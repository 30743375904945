<template>
  <div class="container">
    <div class="card theme-card border-lg-0">
      <div class="card-body px-2 px-sm-4">
        <div class="row">
          <div
            class="
              col-12 col-lg-4
              mb-3
              d-flex
              align-items-md-center
              flex-md-row flex-column
            "
          >
            <label class="col-4 col-sm-auto col-form-label mb-md-0 mb-2">
              Service:
            </label>
            <multiselect
              class="app-select"
              v-model="selectedService"
              :options="dropdownServices"
              :loading="activeServicesLoading"
              placeholder="Search for Service"
              value="value"
              label="label"
              track-by="value"
              :required="true"
            >
            </multiselect>
          </div>
          <div
            class="
              col-12 col-lg-4
              mb-3
              d-flex
              align-items-md-center
              flex-md-row flex-column
            "
          >
            <label class="col-auto col-form-label mb-md-0 mb-2">
              Treatment:
            </label>
            <multiselect
              class="app-select"
              v-model="selectedTreatment"
              :options="dropdownTreatments"
              placeholder="Search for Treatment"
              Value="value"
              label="label"
              track-by="value"
              :loading="basicTreatmentsLoading"
            >
            </multiselect>
          </div>
          <div
            class="
              col-12 col-lg-4
              mb-3
              d-flex
              align-items-md-center
              flex-md-row flex-column
            "
          >
            <label class="col-auto col-form-label mb-md-0 mb-2">
              Booking Status:
            </label>
            <select v-model="bookingStatus" class="form-control">
              <option
                v-for="option in bookingStatusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-end flex-wrap">
          <div
            class="d-flex align-items-center mr-auto text-dark col-auto mb-3"
          >
            <span>
              <b>
                <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
                {{ busy ? "" : bookingRequests.length }}/{{ total }}
              </b>
              Booking Request{{
                bookingRequestsLoading || total != 1 ? "s" : ""
              }}
            </span>
          </div>
        </div>
      </div>
      <alert v-if="bookingRequestsLoading" class="light-shadow mx-4 mb-4" />
      <alert
        class="mx-4 mb-4"
        :hideLoader="true"
        v-else-if="bookingRequests.length === 0"
      >
        No booking requests found.
      </alert>
      <div
        class="
          card
          mb-0
          theme-card
          mobile-tablet-no-card
          p-2 p-md-0
          table-container
          border-lg-0
        "
        v-else
      >
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>Client</th>
              <th>Service</th>
              <th>Treatment</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Additional Notes</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="bookingRequest in bookingRequests"
              :key="bookingRequest.id"
            >
              <td>
                <b class="mobile-tablet-only mr-2">Name:</b>
                {{ bookingRequest.user.name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Service:</b>
                {{ bookingRequest.treatment.service.name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Treatment:</b>
                {{ bookingRequest.treatment.name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Start Time:</b>
                {{ dateFormat(bookingRequest.start_time) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">End Time:</b>
                {{ dateFormat(bookingRequest.end_time) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Additional Notes:</b>
                <button
                  class="btn btn-theme"
                  @click="showNotes(bookingRequest)"
                >
                  <icon type="eye fa-lg" />
                </button>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Status:</b>
                <span
                  class="font-weight-bold"
                  :class="
                    {
                      pending: 'badge badge-pill badge-warning',
                      accepted: 'badge badge-pill badge-success',
                      rejected: 'badge badge-pill badge-danger',
                    }[bookingRequest.status]
                  "
                >
                  {{ statusToUpperCase(bookingRequest.status) }}
                </span>
              </td>
              <td class="td-actions">
                <div
                  class="d-flex justify-content-center justify-content-xl-start"
                >
                  <button
                    v-if="bookingRequest.status === 'pending'"
                    class="btn btn-black ml-1 mr-2"
                    title="Accept Booking Request"
                    @click="acceptBookingRequestHelper(bookingRequest)"
                  >
                    <icon type="calendar-check fa-lg" />
                  </button>
                  <button
                    v-if="bookingRequest.status === 'pending'"
                    class="btn btn-danger ml-1 mr-2"
                    title="Reject Booking Request"
                    @click="rejectBookingRequestHelper(bookingRequest)"
                  >
                    <icon type="calendar-xmark fa-lg" />
                  </button>
                  <a
                    v-tooltip="'Create New Intake Form'"
                    class="ml-2"
                    :href="`/clients/${bookingRequest.user.profile_id}/intake-forms/create`"
                    target="_blank"
                  >
                    <img
                      class="tools-img light-shadow rounded-circle border"
                      src="@/assets/img/firstHx-logo1.png"
                      width="43"
                      alt="Create New Intake Form"
                    />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll
          :handler="loadMore"
          :should-handle="
            !busy && !bookingRequestsLoading && bookingRequests.length < total
          "
          scroll-container="wrap"
        >
          <alert
            v-if="busy"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
            "
          >
            <i class="fas fa-spin fa-circle-notch mr-2"></i>
            Loading more requests...
          </alert>
        </mugen-scroll>
      </div>
    </div>
    <accept-booking-request-modal
      v-if="triggeredBookingRequest"
      :booking-request="triggeredBookingRequest"
    />
    <reject-booking-request-modal
      v-if="triggeredBookingRequest"
      :booking-request="triggeredBookingRequest"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import dayjs from "dayjs";
import MugenScroll from "vue-mugen-scroll";
import AcceptBookingRequestModal from "./AcceptBookingRequestModal.vue";
import RejectBookingRequestModal from "./RejectBookingRequestModal.vue";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect,
    MugenScroll,
    AcceptBookingRequestModal,
    RejectBookingRequestModal,
  },
  data() {
    return {
      busy: false,
      limit: 50,
      selectedTreatment: null,
      selectedService: null,
      bookingStatus: "pending",
      bookingStatusOptions: [
        { label: "All", value: null },
        { label: "Pending", value: "pending" },
        { label: "Accepted", value: "accepted" },
        { label: "Rejected", value: "rejected" },
      ],
      triggeredBookingRequest: null,
    };
  },
  mounted() {
    this.getBookingRequestsHelper(1);
    this.getActiveServices();
    this.getBasicTreatments();
  },
  watch: {
    selectedTreatment: function () {
      this.getBookingRequestsHelper(1);
    },
    bookingStatus: function () {
      this.getBookingRequestsHelper(1);
    },
    selectedService: function () {
      this.getBookingRequestsHelper(1);
    },
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      bookingRequestsLoading: (state) => state.booking.bookingRequests.loading,
      bookingRequests: (state) => state.booking.bookingRequests.pagination.data,
      total: (state) => state.booking.bookingRequests.pagination.total,
      page: (state) => state.booking.bookingRequests.pagination.page,
      basicTreatments: (state) => state.treatments.basicTreatments.data,
      basicTreatmentsLoading: (state) =>
        state.treatments.basicTreatments.loading,
      activeServices: (state) => state.services.activeServices.data,
      activeServicesLoading: (state) => state.services.activeServices.loading,
    }),
    dropdownTreatments: function () {
      return this.basicTreatments.map((treatment) => ({
        label: treatment.name,
        value: treatment.id,
      }));
    },
    dropdownServices() {
      return this.activeServices.map((service) => ({
        label: service.name,
        value: service.id,
      }));
    },
  },
  methods: {
    ...mapActions({
      getBookingRequests: "booking/getBookingRequests",
      rejectBookingRequest: "booking/rejectBookingRequest",
      getBasicTreatments: "treatments/getBasicTreatments",
      getActiveServices: "services/getActiveServices",
    }),
    dateFormat: function (date) {
      return dayjs(date).utc().format("MMM D, YYYY [at] h:mm a");
    },
    statusToUpperCase: function (status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
    getBookingRequestsHelper: function (page) {
      const payload = {
        limit: this.limit,
        page: page || this.page,
      };
      if (this.selectedTreatment) {
        payload.treatmentId = this.selectedTreatment.value;
      }
      if (this.selectedService) {
        payload.serviceId = this.selectedService.value;
      }
      if (this.bookingStatus) {
        payload.status = this.bookingStatus;
      }

      this.getBookingRequests(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore: function () {
      if (this.bookingRequests.length < this.total) {
        this.busy = true;
        this.getBookingRequestsHelper(this.page + 1);
      }
    },
    acceptBookingRequestHelper: function (bookingRequest) {
      this.triggeredBookingRequest = bookingRequest;
      this.$nextTick(() => {
        this.$modal.show("acceptBookingRequestModal");
      });
    },
    rejectBookingRequestHelper: function (bookingRequest) {
      this.triggeredBookingRequest = bookingRequest;
      this.$nextTick(() => {
        this.$modal.show("rejectBookingRequestModal");
      });
    },
    showNotes: function (bookingRequest) {
      Swal.fire({
        html: `<div class="booking-requests-additional-notes" style="margin-bottom: -24px;">
          <h3>Additional Notes</h3>
          <div class="additional-notes-container d-flex align-items-baseline justify-content-between flex-wrap border rounded p-3 mb-3">
          ${bookingRequest.additional_notes || "No additional notes."}
          </div>
          <hr>
          </div>`,
        showCancelButton: true,
        cancelButtonText: "Close",
        cancelButtonColor: "#6e84a3",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "px-0",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-icon {
  font-size: 1.5rem;
}
</style>
<style lang="scss">
.booking-requests-additional-notes {
  .additional-notes-container {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Credit Card</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body">
        <StripeCard v-if="!isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapActions, mapState } from "vuex";
import StripeCard from "../../components/stripe/StripeCard.vue";

export default {
  components: {
    StripeCard,
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
    }),
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      getSettings: "settings/getSettings",
    }),
    initStripe: async function () {
      try {
        const settings = await this.getSettings();
        if (settings["STRIPE_PUBLIC"] && !window.stripe) {
          const stripe = await loadStripe(settings["STRIPE_PUBLIC"]);
          window.stripe = stripe;
        }
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
  },
  mounted() {
    if (this.patient == null) {
      this.$router.back();
    }
    this.initStripe();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12 col-lg-3 pl-2 pr-0">
      <div class="card d-flex flex-row card-opacity providers-container">
        <alert
          v-if="loadingProviders"
          class="mb-0 w-100 border-0 bg-transparent h-45 shadow-0"
        ></alert>
        <div class="providers-inner-container" v-if="providers.length">
          <router-link
            v-for="provider in providers"
            :key="provider.id"
            :to="
              user && (user.isAdmin || user.isManagingAdmin)
                ? `/providers/${provider.id}`
                : `providers`
            "
            v-tooltip="provider.name"
          >
            <img
              class="user-avatar-img mr-2 rounded-circle"
              :src="provider.profile_picture || profileImagePlaceholder"
            />
          </router-link>
        </div>
        <router-link
          :to="`/clients/${patient.id}/providers`"
          class="plus-providers"
          v-if="
            !loadingProviders && user && (user.isAdmin || user.isManagingAdmin)
          "
          v-tooltip="'View more assigned providers or assign'"
        >
          <div
            class="
              w-100
              h-100
              rounded-circle
              bg-white
              d-flex
              justify-content-center
              align-items-center
              text-dark
            "
          >
            <i class="fas fa-plus"></i>
          </div>
        </router-link>

        <alert
          v-if="!loadingProviders && providers.length === 0"
          :hideLoader="true"
          class="mb-0 w-100 border-0 bg-transparent h-45 shadow-0"
        >
          No Providers
        </alert>
      </div>
      <div class="col-12 col-md-6 col-lg-auto p-0 pr-md-4 pr-lg-0 mb-4">
        <div
          class="
            user-profile-container
            d-flex
            align-items-center
            justify-content-between
            p-3
            dashboard-card
            light-shadow
            bg-img-card
          "
        >
          <div class="user-profile-logos-container d-flex align-items-center">
            <img
              :src="
                patient.profile_picture != null
                  ? patient.profile_picture.split('?response-content')[0]
                  : profileImagePlaceholder
              "
              class="user-picture"
            />
          </div>
          <div
            class="
              user-profile-info
              mb-auto
              col-6
              d-flex
              flex-column
              justify-content-between
            "
          >
            <div class="user-profile-name text-main" v-if="user">
              {{ patient.name }}
            </div>

            <div class="user-profile-role text-main">
              <span class="user-profile-gender-name">
                {{ patientGender || "Other" }}, {{ patientAge }} year{{
                  patientAge > 1 ? "s" : ""
                }}
              </span>
              <p class="user-profile-status ellipsis mt-2 mb-0">
                {{ patient.user.status }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Diagnosis</h3>
            <div
              class="card-info"
              :class="!showMore.diagnosis && 'three-lines'"
            >
              <span
                v-if="
                  !showMore.diagnosis &&
                  patient.diagnosis &&
                  patient.diagnosis.length > 150
                "
              >
                {{ (patient.diagnosis || "N/A").slice(0, 150) }}...
              </span>
              <span
                v-if="
                  showMore.diagnosis || (patient.diagnosis || '').length <= 150
                "
              >
                {{ patient.diagnosis || "N/A" }}
              </span>
            </div>
            <div
              class="text-left mt-auto"
              v-if="patient.diagnosis && patient.diagnosis.length > 150"
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                style="margin-left: -8px"
                @click="viewMore('diagnosis')"
              >
                Show {{ showMore.diagnosis ? "less" : "more" }}
              </button>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-end justify-content-between"
          >
            <button
              class="card-link btn btn-sm btn-link text-main font-weight-bold"
              style="margin-top: -2px"
              v-if="user && !user.isPatient"
              @click="editDetails('diagnosis')"
            >
              Edit
              <i class="fa fa-edit"></i>
            </button>
            <img class="card-icon" src="@/assets/img/patient/diagnosis.png" />
          </div>
        </div>

        <div
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Assessments</h3>
            <div
              class="card-info"
              v-if="patient.assessment_active"
              :class="!showMore.assessment && 'three-lines'"
            >
              <span
                v-if="
                  !showMore.assessment &&
                  patient.assessment &&
                  patient.assessment.length > 150
                "
              >
                {{ (patient.assessment || "N/A").slice(0, 150) }}...
              </span>
              <span
                v-if="
                  showMore.assessment ||
                  (patient.assessment || '').length <= 150
                "
              >
                {{ patient.assessment || "N/A" }}
              </span>
            </div>
            <div class="card-info" v-if="!patient.assessment_active">
              <badge class="badge-danger" style="font-size: 100%"
                >Inactive</badge
              >
            </div>
            <div
              class="text-left mt-auto"
              v-if="
                patient.assessment &&
                patient.assessment.length > 150 &&
                patient.assessment_active
              "
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                style="margin-left: -8px"
                @click="viewMore('assessment')"
              >
                Show {{ showMore.assessment ? "less" : "more" }}
              </button>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-end justify-content-between"
          >
            <button
              class="card-link btn btn-sm btn-link text-main font-weight-bold"
              style="margin-top: -2px"
              v-if="user && !user.isPatient"
              @click="editDetails('assessments')"
            >
              Edit
              <i class="fa fa-edit"></i>
            </button>
            <img class="card-icon" src="@/assets/img/patient/assessment.png" />
          </div>
        </div>
        <div
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Treatment</h3>
            <div
              class="card-info"
              v-if="patient.treatment_active"
              :class="!showMore.treatment && 'three-lines'"
            >
              <span
                v-if="
                  !showMore.treatment &&
                  patient.treatment &&
                  patient.treatment.length > 150
                "
              >
                {{ (patient.treatment || "N/A").slice(0, 150) }}...
              </span>
              <span
                v-if="
                  showMore.treatment || (patient.treatment || '').length <= 150
                "
              >
                {{ patient.treatment || "N/A" }}
              </span>
            </div>
            <div class="card-info" v-if="!patient.treatment_active">
              <badge class="badge-danger" style="font-size: 100%"
                >Inactive</badge
              >
            </div>
            <div
              class="text-left mt-auto"
              v-if="
                patient.treatment &&
                patient.treatment.length > 150 &&
                patient.treatment_active
              "
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                style="margin-left: -8px"
                @click="viewMore('treatment')"
              >
                Show {{ showMore.treatment ? "less" : "more" }}
              </button>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-end justify-content-between"
          >
            <button
              class="card-link btn btn-sm btn-link text-main font-weight-bold"
              style="margin-top: -2px"
              v-if="user && !user.isPatient"
              @click="editDetails('treatment')"
            >
              Edit
              <i class="fa fa-edit"></i>
            </button>
            <img class="card-icon" src="@/assets/img/patient/medicine.png" />
          </div>
        </div>
        <div
          class="dashboard-card info-card d-flex justify-content-between"
          style="height: calc(100% - 4px)"
        >
          <div class="d-flex flex-column info-container">
            <h3 class="card-title">Intervention/Plan</h3>
            <div
              class="card-info"
              v-if="patient.intervention_active"
              :class="!showMore.intervention && 'three-lines'"
            >
              <span
                v-if="
                  !showMore.intervention &&
                  patient.intervention &&
                  patient.intervention.length > 150
                "
              >
                {{ (patient.intervention || "N/A").slice(0, 150) }}...
              </span>
              <span
                v-if="
                  showMore.intervention ||
                  (patient.intervention || '').length <= 150
                "
              >
                {{ patient.intervention || "N/A" }}
              </span>
            </div>
            <div class="card-info" v-if="!patient.intervention_active">
              <badge class="badge-danger" style="font-size: 100%"
                >Inactive</badge
              >
            </div>
            <div
              class="text-left mt-auto"
              v-if="
                patient.intervention &&
                patient.intervention.length > 150 &&
                patient.intervention_active
              "
            >
              <button
                class="card-link btn btn-sm btn-link text-main font-weight-bold"
                style="margin-left: -8px"
                @click="viewMore('intervention')"
              >
                Show {{ showMore.intervention ? "less" : "more" }}
              </button>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-end justify-content-between"
          >
            <button
              class="card-link btn btn-sm btn-link text-main font-weight-bold"
              style="margin-top: -2px"
              v-if="user && !user.isPatient"
              @click="editDetails('intervention')"
            >
              Edit
              <i class="fa fa-edit"></i>
            </button>
            <img
              class="card-icon"
              src="@/assets/img/patient/intervention.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <div class="col-12 mb-3 pb-4 flex-shrink-1">
        <ul
          class="
            nav nav-tabs
            header-tabs
            noPrint
            profile-nav-container
            patient-nav-container
          "
        >
          <li
            class="nav-item mr-1 mb-2"
            :class="{
              'u-display-none': isHideTab(link),
            }"
            v-for="link in nav"
            :key="link.to || link.action"
          >
            <router-link
              v-if="
                (!link.patientHide || (user && !user.isPatient)) && !link.action
              "
              :to="getLink(link.to)"
              class="nav-link"
              :class="$route.name == link.to ? `active` : ``"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </router-link>
            <a
              v-if="
                (!link.patientHide || !user || !user.isPatient) && link.action
              "
              href="/"
              @click="openCalendarModal"
              class="nav-link"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 card theme-card" :class="currentViewName">
        <keep-alive>
          <router-view class="pt-4"></router-view>
        </keep-alive>
      </div>
      <sidebar
        :toggle="modalOpened"
        @close="closeCalendarModal"
        :title="`${patient.name} Events`"
        wrapClass="calendar-tray"
        class="calendar-modal"
      >
        <Calendar
          :key="Math.random()"
          :profilePatientUserId="patient.user_id"
          :profileName="patient.name"
          @closeModal="modalOpened = false"
          v-if="modalOpened"
        ></Calendar>
      </sidebar>
    </div>
    <modal
      class="patient-details-modal"
      name="patient-details-modal"
      transition="pop-out"
      :clickToClose="false"
      :width="800"
      height="auto"
    >
      <div class="position-relative main-modal-container">
        <div class="vm--top-right-slot border-bottom pb-3" style="z-index: 1">
          <h2
            class="
              position-absolute
              m-3
              pt-1
              mr-5
              text-left
              font-weight-bold
              pointer-none
            "
            style="letter-spacing: 0.1px"
          >
            Edit
          </h2>
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            :disabled="updatingPatientDetails"
            @click="closePatientDetailsModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body">
          <div class="pb-0 d-flex flex-column" v-if="patientData">
            <div
              :class="`field-container${
                selectedFeild == 'diagnosis' ? ' selected' : ''
              }`"
            >
              <input-control
                type="horizontal"
                v-model="patientData.diagnosis"
                control="textarea"
                class="input-control"
                labelClass="col-md-3"
                :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
                >Diagnosis</input-control
              >
            </div>
            <div
              :class="`field-container${
                selectedFeild == 'assessments' ? ' selected' : ''
              }`"
            >
              <div class="form-group row">
                <label class="col-md-2 col-form-label col-md-3"
                  >Assessments Active?</label
                >
                <div class="col">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="modal_assessment_active"
                      v-model="patientData.assessment_active"
                      @change="$forceUpdate()"
                    />
                    <label
                      class="custom-control-label pointer-cursor"
                      for="modal_assessment_active"
                      >{{
                        patientData.assessment_active ? "Active" : "Inactive"
                      }}</label
                    >
                  </div>
                </div>
              </div>
              <input-control
                v-if="patientData.assessment_active"
                type="horizontal"
                labelClass="col-md-3"
                v-model="patientData.assessment"
                control="textarea"
                >Assessments</input-control
              >
            </div>

            <div
              :class="`field-container${
                selectedFeild == 'treatment' ? ' selected' : ''
              }`"
            >
              <div class="form-group row">
                <label class="col-md-2 col-form-label col-md-3"
                  >Treatment Active?</label
                >
                <div class="col">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="modal_treatment_active"
                      v-model="patientData.treatment_active"
                      @change="$forceUpdate()"
                    />
                    <label
                      class="custom-control-label pointer-cursor"
                      for="modal_treatment_active"
                      >{{
                        patientData.treatment_active ? "Active" : "Inactive"
                      }}</label
                    >
                  </div>
                </div>
              </div>

              <input-control
                v-if="patientData.treatment_active"
                type="horizontal"
                labelClass="col-md-3"
                v-model="patientData.treatment"
                control="textarea"
                >Treatment</input-control
              >
            </div>

            <div
              :class="`field-container${
                selectedFeild == 'intervention' ? ' selected' : ''
              }`"
            >
              <div class="form-group row">
                <label class="col-md-2 col-form-label col-md-3"
                  >Intervention Active?</label
                >
                <div class="col">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="modal_intervention_active"
                      v-model="patientData.intervention_active"
                      @change="$forceUpdate()"
                    />
                    <label
                      class="custom-control-label pointer-cursor"
                      for="modal_intervention_active"
                      >{{
                        patientData.intervention_active ? "Active" : "Inactive"
                      }}</label
                    >
                  </div>
                </div>
              </div>

              <input-control
                v-if="patientData.intervention_active"
                type="horizontal"
                labelClass="col-md-3"
                v-model="patientData.intervention"
                control="textarea"
                >Intervention/Plan</input-control
              >
            </div>
          </div>
          <div class="saving-note-container" v-if="updatingPatientDetails">
            <div class="saving-note alert alert-info mb-1 py-1" role="alert">
              This may take few moments...
            </div>
          </div>
        </div>
        <div class="py-3 d-flex justify-content-center border-top">
          <save
            :shouldDisable="updatingPatientDetails"
            :saving="updatingPatientDetails"
            :savingText="'Update'"
            @click="updatePatientDetails()"
            classes="btn btn-black mr-4"
            >Update</save
          >
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Calendar from "../appointment/calendar";
import Alert from "@/components/Alert.vue";

export default {
  components: {
    Alert,
    Calendar,
  },
  data() {
    return {
      loadingProviders: true,
      updatingPatientDetails: false,
      selectedFeild: "",
      patientData: null,
      providers: [],
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      modalOpened: false,
      showMore: {
        diagnosis: false,
        assessment: false,
        treatment: false,
        intervention: false,
      },
      nav: [
        {
          label: "Dashboard",
          to: "patients.dashboard",
          icon: "chart-line",
        },
        {
          label: "General",
          to: "patients.basic",
          icon: "home",
        },
        {
          label: "Clinics",
          to: "patients.clinics",
          icon: "clinic-medical",
          patientHide: true,
        },
        {
          label: "Classes",
          to: "patients.classes",
          icon: "tags",
          patientHide: true,
        },
        {
          label: "Address",
          to: "patients.address",
          icon: "map-signs",
        },
        {
          label: "Phones",
          to: "patients.phones",
          icon: "phone",
        },
        {
          label: "Emails",
          to: "patients.emails",
          icon: "at",
        },
        {
          label: "Services",
          to: "patients.services",
          icon: "cogs",
        },
        {
          label: "Credit Cards",
          to: "creditCards",
          icon: "credit-card",
        },
        {
          label: "Transactions",
          to: "transactions",
          icon: "money-check",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      stripeConfig: (state) => state.stripeConfig,
      patient: (state) => state.patients.patient,
    }),
    currentViewName() {
      return this.$route.name.replace(".", "-"); // Access the current route's name
    },
    patientAge() {
      if (this.patient && this.patient.user) {
        const today = new Date();
        const birthDate = new Date(this.patient.user.birth_date);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        return age;
      }
      return "";
    },
    patientGender() {
      if (this.patient && this.patient.user) {
        const genderLabels = ["Other", "Male", "Female"];
        return genderLabels[this.patient.user.gender];
      }
      return "N/A";
    },
  },
  methods: {
    ...mapActions({
      getClientProviders: "patients/providers/getClientProviders",
      updatePatient: "patients/update",
      getStripeConfig: "stripeConfig/getStripeConfig",
    }),
    viewMore(type) {
      this.showMore[type] = !this.showMore[type];
      this.$forceUpdate();
    },
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.$route.params.id,
        },
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
    editDetails(fieldName) {
      this.selectedFeild = fieldName;
      this.patientData = JSON.parse(JSON.stringify(this.patient));
      this.$modal.show("patient-details-modal");
    },
    closePatientDetailsModal() {
      this.selectedFeild = "";
      this.$modal.hide("patient-details-modal");
    },
    isHideTab(link) {
      if (link.to === "transactions" || link.to === "creditCards") {
        return (
          this.stripeConfig?.isloading || !this.stripeConfig?.data?.is_enabled
        );
      } else {
        return (
          link.hide || (link.patientHide && (!this.user || this.user.isPatient))
        );
      }
    },
    updatePatientDetails() {
      this.updatingPatientDetails = true;
      const data = {
        patient: this.patient.id,
        diagnosis:
          this.user && (this.user.isAdmin || this.user.isManagingAdmin)
            ? this.patientData.diagnosis
            : this.patient.diagnosis,
        assessment: this.patientData.assessment,
        treatment: this.patientData.treatment,
        intervention: this.patientData.intervention,
        assessment_active: this.patientData.assessment_active,
        treatment_active: this.patientData.treatment_active,
        intervention_active: this.patientData.intervention_active,
      };
      this.updatePatient(data).then(() => {
        this.$store
          .dispatch("patients/get", {
            id: this.patientData.id,
            noLoading: true,
          })
          .then(() => {
            this.updatingPatientDetails = false;
            this.closePatientDetailsModal();
          });
      });
    },
  },
  mounted() {
    this.getClientProviders(this.patient.id).then((data) => {
      if (data) {
        this.providers = data;
      }
      this.loadingProviders = false;
    });
    this.getStripeConfig();
  },
};
</script>

<style scoped lang="scss">
.patients-dashboard {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  margin-top: -24px;
}
.user-avatar-img,
.plus-providers {
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.plus-providers {
  position: absolute;
  right: 0;
}
.providers-container {
  position: relative;
  padding-right: 50px;
  margin-bottom: 16px;
  .providers-inner-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(51px, 51px));
    grid-auto-flow: row;
    justify-content: start;
    height: 45px;
    overflow: hidden;
  }
}

.patient-nav-container {
  .nav-link {
    padding: 10px 16px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 99px;
    color: black;

    &.active {
      background-color: black;
      color: white;
      border: 0;
    }
  }
}

.dashboard-card {
  background-color: #ffffff;
  border-radius: 36px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
}

.info-card {
  min-height: 136px;
  padding: 16px 12px 16px 24px;
  margin-top: 4px;

  .info-container {
    width: 100%;
    padding-right: 4px;
  }

  .card-icon {
    max-width: 60px;
    max-height: 100%;
    margin-top: auto;
  }

  .card-info {
    margin-top: 12px;
    font-size: 15px;
  }
}

.user-profile-container {
  min-height: 280px;
  padding-bottom: 50px !important;
  height: auto;
}

.user-profile-info {
  background-color: rgba(235, 236, 235, 0.5);
  border-radius: 36px;
  min-height: 170px;
  padding: 12px;
}

.user-profile-logos-container {
  width: 60%;
  padding: 0 8px 0 0;
  .user-picture {
    width: 100%;
    max-height: 100%;
    border-radius: 36px;
    min-height: 210px;
    object-position: center;
    object-fit: cover;
  }
}

.bg-img-card {
  background-image: var(--profile-img-path);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 50%;
}
.user-profile-name {
  padding: 8px;
  font-size: 23px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile-role {
  margin-top: 12px;
}

.user-profile-gender-name,
.user-profile-status {
  border-radius: 99px;
  background-color: rgba(255, 255, 255, 0.75);
  color: var(--main-text-color);
  padding: 4px 10px;
  font-size: 14px;
  font-weight: normal;
  height: 29px;
}
</style>

<style lang="scss">
.patient-details-modal {
  .v--modal-box {
    transition: all 0.1s ease-in-out;
    max-height: 96vh;
    overflow: hidden;
    .card-body {
      background-color: var(--body-bg-color);
      max-height: calc(96vh - 136px);
      overflow-y: auto;
      padding-bottom: 0;
    }
  }

  .field-container {
    order: 2;
    &.selected {
      order: 1;
    }
  }
  .saving-note-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 72px;
    left: 0;
    right: 0;
    z-index: 1;
    text-align: center;
    .saving-note {
      color: #0c5460;
      background-color: #d1ecf1;
      border-color: #bee5eb;
    }
  }
}
</style>

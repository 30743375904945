<template>
  <div id="app">
    <public v-if="publicRoute" />
    <authed v-else-if="isLoggedIn" />
    <guest v-else />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Authed from "./layouts/Authed";
import Guest from "./layouts/Guest";
import Swal from "sweetalert2";
import Public from "./layouts/Public.vue";

export default {
  components: {
    Authed,
    Guest,
    Public,
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.authenticated,
      authedUser: (state) => state.auth.user,
      termsAcceptedDate: (state) => state.auth.user?.terms_accepted_date,
    }),
    publicRoute: function () {
      return this.$route.meta.public;
    },
  },
  created() {
    this.updateSystemUI();
    this.getProfileLogo();
    this.checkIfUserLoggedIn()
      .then((res) => {
        if (res) {
          if (!this.authedUser) {
            this.getUserDetails().then(() => {
              this.getCompanyProfile();
              this.showPrivacyModal();
            });
          }
        } else {
          this.doLogout();
        }
      })
      .catch((err) => {
        //eslint-disable-next-line
        console.log(err);
      });
  },
  methods: {
    ...mapActions({
      checkIfUserLoggedIn: "auth/setLoggedIn",
      getUserDetails: "auth/getUser",
      getProfileLogo: "settings/getCompanyProfileLogo",
      getCompanyProfile: "settings/getCompanyProfile",
      setAcceptTerms: "auth/setAcceptTerms",
      doLogout: "auth/logout",
    }),
    updateSystemUI: function () {
      const storedTheme = localStorage.getItem("THEME")
        ? JSON.parse(localStorage.getItem("THEME"))
        : null;
      if (storedTheme) {
        document.documentElement.style.setProperty(
          "--theme-color",
          storedTheme.themeColor
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          storedTheme.secondaryColor
        );
        document.documentElement.style.setProperty(
          "--body-bg-color",
          storedTheme.bodyColor
        );
        document.documentElement.style.setProperty(
          "--main-text-color",
          storedTheme.textColor
        );
        document.documentElement.style.setProperty(
          "--btn-text-color",
          storedTheme.btnColor
        );
        document.documentElement.style.setProperty(
          "--tools-img-path",
          `url('${storedTheme.toolsImg}')`
        );
        document.documentElement.style.setProperty(
          "--notes-img-path",
          `url('${storedTheme.notesImg}')`
        );
        document.documentElement.style.setProperty(
          "--profile-img-path",
          `url('${storedTheme.profileImg}')`
        );
      }
    },
    showPrivacyModal: function () {
      if (!this.termsAcceptedDate) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black",
            cancelButton: "btn btn-danger ml-4",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            icon: "warning",
            title: "Terms & Conditions",
            html: `<div class="justify-text">By clicking the "I Accept" button displayed as part of the software loading process, you agree to the following terms and conditions (The "agreement") covering your use of the services and the C3 website whether in connection with a paid or trial period subscription. If you are entering into this agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these terms and conditions, in which case the terms "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with these terms and conditions, you must select the "I Decline" button and may not use the service.</div>`,
            showCancelButton: true,
            confirmButtonText: "I Accept",
            cancelButtonText: "I Decline",
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.setAcceptTerms();
            } else {
              this.doLogout().then((res) => {
                if (res) {
                  this.$router.push({ path: "/" });
                  window.location.href = `${window.location.origin}`;
                }
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/theme.scss";
</style>
<style lang="css">
@import "assets/fonts/feather/feather.css";
@import "assets/fonts/ttcommons/ttcommons.css";
@import "assets/css/common.css";
</style>

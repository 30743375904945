<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Add Client</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body">
        <form @submit.prevent="addPatient">
          <input-control
            v-model="patients.first"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >First Name</input-control
          >
          <input-control
            v-model="patients.last"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >Last Name</input-control
          >
          <input-control
            v-model="patients.email"
            labelClass="col-md-3 col-lg-2"
            required
            type="horizontal"
            control="email"
            >Email</input-control
          >
          <input-control
            v-model="patients.password"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            control="password"
            >Password</input-control
          >
          <input-control
            v-model="patients.phone_number"
            type="horizontal"
            :cleave="cleave.phone"
            labelClass="col-md-3 col-lg-2"
            >Phone Number</input-control
          >

          <div class="form-group row">
            <label class="col-md-3 col-lg-2" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="patients.birth_date"
                zone="UTC"
                :week-start="7"
                placeholder=""
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <select-control
            v-model="patients.status"
            :options="statuses"
            required
            labelClass="col-md-3 col-lg-2 col-12"
            >Status</select-control
          >

          <div class="form-group row">
            <label class="col-form-label col-12 col-md-3 col-lg-2"
              >HCAI Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hcai_active"
                  v-model="patients.hcai_active"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="hcai_active"
                  >{{ patients.hcai_active ? "Active" : "Inactive" }}</label
                >
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="patients.hcai_active">
            <label class="col-form-label col-12 col-md-3 col-lg-2"
              >HCAI CAT?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="hcai_cat"
                  v-model="patients.hcai_cat"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="hcai_cat"
                  >{{ patients.hcai_cat ? "Yes" : "No" }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-model="healthCard"
            :cleave="cleave.hcard"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >Health Card</input-control
          >
          <input-control
            v-model="patients.insurer"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >Insurer</input-control
          >
          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label
              class="administrator-id-label col-md-3 col-lg-2 col-form-label"
              for="administrator_id"
              >Client Administrators</label
            >
            <div class="col">
              <vue-tags-input
                id="administrator_id"
                name="administrator_id"
                class="form-control"
                v-model="tag"
                :tags="adminTags"
                :autocomplete-items="filteredItems"
                :add-only-from-autocomplete="true"
                :autocomplete-min-length="0"
                placeholder=""
                @tags-changed="(newTags) => (adminTags = newTags)"
              />
            </div>
          </div>

          <input-control
            v-model="sin"
            :cleave="cleave.sin"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >S.I.N</input-control
          >

          <input-control
            v-model="patients.interest_percentage"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
            labelClass="col-md-3 col-lg-2"
            type="horizontal"
            control="number"
            step="0.01"
            min="0"
            >Interest Percentage (%)</input-control
          >
          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-form-label col-md-3 col-lg-2"
              >Interest Compounded?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-interest-compounded"
                  v-model="patients.is_compound"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="is-interest-compounded"
                  >{{ patients.is_compound ? "Yes" : "No" }}</label
                >
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for class="col-md-3 col-lg-2 col-form-label"
              >Funding Source</label
            >
            <div class="col">
              <select
                v-model="patients.funding_source"
                class="form-control"
                required
              >
                <option
                  v-for="p in fundingSourceOptions"
                  :key="p.value"
                  :value="p.value"
                >
                  {{ p.label }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
          >
            <label class="col-md-3 col-lg-2 col-form-label" for="referrers_ids"
              >Referred By</label
            >
            <div class="col">
              <vue-tags-input
                id="referrers_ids"
                name="referrers_ids"
                class="form-control"
                v-model="referrerTag"
                :tags="referrerTags"
                :autocomplete-items="referrerFilteredItems"
                :add-only-from-autocomplete="true"
                :autocomplete-min-length="0"
                placeholder=""
                @tags-changed="(newTags) => (referrerTags = newTags)"
              />
            </div>
            <router-link
              class="mr-3 text-center"
              to="/admin/referral-sources/create"
              >+ Add a new Referral Source</router-link
            >
          </div>
          <input-control
            type="horizontal"
            v-model="patients.diagnosis"
            labelClass="col-md-3 col-lg-2"
            control="textarea"
            >Diagnosis</input-control
          >
          <div class="form-group row">
            <label class="col-md-3 col-lg-2" for="input-control-injured_at"
              >Date Of Injury</label
            >
            <div class="col">
              <datetime
                id="input-control-injured_at"
                v-model="patients.injured_at"
                zone="UTC"
                :week-start="7"
                placeholder=""
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
          <input-control
            type="horizontal"
            v-model="patients.claim_number"
            labelClass="col-md-3 col-lg-2"
            >Claim Number</input-control
          >
          <input-control
            type="horizontal"
            v-model="patients.policy_number"
            labelClass="col-md-3 col-lg-2"
            >Policy Number</input-control
          >

          <input-control
            type="horizontal"
            v-model="patients.bio"
            control="textarea"
            labelClass="col-md-3 col-lg-2"
            >Approved Hours</input-control
          >

          <div class="form-group row">
            <label class="col-form-label col-md-3 col-lg-2"
              >Assessments Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="assessment_active"
                  v-model="patients.assessment_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="assessment_active"
                  >{{
                    patients.assessment_active ? "Active" : "Inactive"
                  }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-if="patients.assessment_active"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            v-model="patients.assessment"
            control="textarea"
            >Assessments</input-control
          >

          <div class="form-group row">
            <label class="col-form-label col-md-3 col-lg-2"
              >Treatment Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="treatment_active"
                  v-model="patients.treatment_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="treatment_active"
                  >{{
                    patients.treatment_active ? "Active" : "Inactive"
                  }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-if="patients.treatment_active"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            v-model="patients.treatment"
            control="textarea"
            >Treatment</input-control
          >

          <div class="form-group row">
            <label class="col-form-label col-md-3 col-lg-2"
              >Intervention Active?</label
            >
            <div class="col">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="intervention_active"
                  v-model="patients.intervention_active"
                  @change="$forceUpdate()"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="intervention_active"
                  >{{
                    patients.intervention_active ? "Active" : "Inactive"
                  }}</label
                >
              </div>
            </div>
          </div>

          <input-control
            v-if="patients.intervention_active"
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            v-model="patients.intervention"
            control="textarea"
            >Intervention/Plan</input-control
          >

          <div class="form-group row align-items-center" v-if="false">
            <label class="col-auto col-md-3 col-lg-2 mb-0"
              >Receive Notifications Via</label
            >
            <div class="d-flex col mr-2">
              <div class="custom-control custom-switch mr-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingSMS"
                  v-model="patients.enable_sending_sms"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingSMS"
                  >SMS</label
                >
              </div>
              <div class="custom-control custom-switch ml-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingEmail"
                  v-model="patients.enable_sending_email"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingEmail"
                  >Email</label
                >
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="mt-3 btn btn-black save-button"
            :disabled="isSubmitting"
          >
            <span
              ><i
                class="mr-2 fas fa-check-circle fa-fw"
                v-if="!isSubmitting"
              ></i>
              <i class="mr-2 fa fa-spin fa-circle-notch" v-else></i>
              Create</span
            >
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import { mapActions, mapState } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import Swal from "sweetalert2";
import { isEmpty } from "lodash";

export default {
  name: "patientAdd",
  components: {
    datetime: Datetime,
    VueTagsInput,
  },
  async mounted() {
    try {
      let users = [];
      const usersRes = await this.$http.get("admins-managers-info");
      usersRes.data.forEach((u) => {
        u = { text: u.name, value: u.id, id: u.id };
        users.push(u);
      });
      this.users = users;
      this.autocompleteItems = users;
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }
    // fill referral sources autocomplete items
    const _autocompleteItems = [];
    await this.getBasicReferralSources();
    this.allReferralSources.forEach((item) => {
      _autocompleteItems.push({
        text: item.name,
        value: item.id,
        id: item.id,
      });
    });
    this.referrersAutocompleteItems = _autocompleteItems;
  },
  beforeDestroy() {
    window.localStorage.removeItem("patient_form");
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allReferralSources: (state) =>
        state.referralSources.allReferralSources.data,
      allReferralSourcesLoading: (state) =>
        state.referralSources.allReferralSources.loading,
    }),
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1;
      });
    },
    referrerFilteredItems() {
      return this.referrersAutocompleteItems.filter((i) => {
        return (
          i.text.toLowerCase().indexOf(this.referrerTag.toLowerCase()) != -1
        );
      });
    },
  },
  watch: {
    ...["patients", "sin", "adminTags", "referrerTags", "healthCard"].reduce(
      (acc, currentKey) => {
        acc[currentKey] = {
          handler() {
            this.saveValuesToLS();
          },
          deep: true,
        };
        return acc;
      },
      {}
    ),
  },
  created() {
    this.uploadFromLS();
  },
  data() {
    return {
      isSubmitting: false,
      statuses: [
        { value: "ACTIVE", label: "Active" },
        { value: "PROTECTED", label: "Protected Account" },
        { value: "PENDING", label: "Pending" },
        { value: "ON_HOLD", label: "On Hold" },
        { value: "INACTIVE", label: "Inactive" },
      ],
      fundingSourceOptions: [
        { value: "government", label: "Government" },
        { value: "insurance_company", label: "Insurance company" },
        { value: "other", label: "Other" },
      ],
      sin: "",
      healthCard: "",
      cleave: {
        sin: { blocks: [3, 3, 3], delimiter: " ", numericOnly: true },
        hcard: { blocks: [4, 3, 3], delimiter: " ", numericOnly: false },
        phone: { blocks: [20], numericOnly: true },
      },
      patients: {
        enable_sending_sms: false,
        enable_sending_email: false,
        hcai_active: false,
        hcai_cat: false,
      },
      users: [],
      tag: "",
      adminTags: [],
      autocompleteItems: [],
      referrerTag: "",
      referrerTags: [],
      referrersAutocompleteItems: [],
    };
  },
  methods: {
    ...mapActions({
      getAllUsers: "security/getUsersWithRoles",
      createClient: "patients/create",
      getBasicReferralSources: "referralSources/getBasicReferralSources",
    }),
    addPatient: function () {
      let data = this.patients;
      if (!data.hcai_active) {
        data.hcai_cat = false;
      }
      data.username = data.email;

      const userIds = [];
      this.adminTags.forEach((el) => {
        userIds.push(el.id);
      });
      data.administrator_ids = userIds;

      const referralSourcesIds = [];
      this.referrerTags.forEach((tag) => {
        referralSourcesIds.push(tag.id);
      });
      data.referredByIds = referralSourcesIds;

      (data.sin = this.sin.replaceAll(" ", "")),
        (data.healthcard = this.healthCard.replaceAll(" ", ""));
      if (data.sin.length && data.sin.length != 9 && data.sin != "*****") {
        return Swal.fire({
          title: "S.I.N Validation",
          text: "S.I.N should be 9 digits",
          icon: "error",
        });
      }
      this.isSubmitting = true;
      this.$forceUpdate();
      this.createClient(data).then((res) => {
        this.isSubmitting = false;
        this.$forceUpdate();
        window.localStorage.removeItem("patient_form");
        if (res) {
          this.getAllUsers();
        }
      });
    },
    saveValuesToLS: function () {
      try {
        window.localStorage.setItem(
          "patient_form",
          JSON.stringify({
            patients: this.patients,
            sin: this.sin,
            healthCard: this.healthCard,
            referrerTags: this.referrerTags,
            adminTags: this.adminTags,
          })
        );
      } catch (err) {
        //eslint-disable-next-line
        console.log(err);
      }
    },
    uploadFromLS: function () {
      try {
        const patientForm =
          JSON.parse(window.localStorage.getItem("patient_form")) || {};
        if (!isEmpty(patientForm)) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-black ml-4",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          return swalWithBootstrapButtons
            .fire({
              title: "Did you lose your data?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, restore!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.patients = {
                  ...this.patients,
                  ...(patientForm.patients || {}),
                };
                this.sin = patientForm.sin || "";
                this.healthCard = patientForm.healthCard || "";
                this.adminTags = patientForm.adminTags || [];
                this.referrerTags = patientForm.referrerTags || [];
              }
              window.localStorage.removeItem("patient_form");
            });
        }
      } catch (e) {
        //eslint-disable-next-line
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
#administrator_id,
#referrers_ids {
  min-height: 4px;
  padding: 0;
  .ti-input {
    border: 0;
    .ti-tags {
      li {
        height: 32px;
        margin-top: 0;
      }
    }
  }
  .ti-new-tag-input-wrapper input {
    height: 100%;
  }
}
</style>

<template>
  <div>
    <alert class="my-4" v-if="stripeConfig.isLoading" />
    <div
      class="stripe-config-body card border-0 mobile-tablet-no-card p-5"
      v-if="!stripeConfig.isLoading"
    >
      <h1 class="mb-4">Stripe configuration</h1>
      <select-control
        type="horizontal"
        :options="yesNoOptions"
        required
        labelClass="col-12 col-md-3 col-lg-2"
        v-model="stripeConfig.data.is_enabled"
        >Enable</select-control
      >
      <input-control
        type="horizontal"
        v-model="stripeConfig.data.public_key"
        required
        labelClass="col-md-3 col-lg-2"
        >Public Key</input-control
      >
      <input-control
        type="horizontal"
        v-model="stripeConfig.data.private_key"
        required
        labelClass="col-md-3 col-lg-2"
        >Private Key</input-control
      >
      <select-control
        type="horizontal"
        :options="currencyOptions"
        required
        labelClass="col-12 col-md-3 col-lg-2"
        v-model="stripeConfig.data.currency"
        >Currency</select-control
      >
      <div class="text-center">
        <button
          :saving="stripeConfig.isSaving"
          class="btn btn-black mb-3 w-25"
          @click.prevent="saveChanges"
        >
          <span
            class="spinner-border spinner-border-sm"
            v-if="stripeConfig.isSaving"
          ></span>
          <span v-else>Save configuration</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "stripe.config",
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      stripeConfig: (state) => state.stripeConfig,
      patient: (state) => state.patients.patient,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.getStripeConfig();
  },
  data: () => ({
    yesNoOptions: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    currencyOptions: [
      { label: "USD", value: "usd" },
      { label: "CAD", value: "cad" },
    ],
  }),
  methods: {
    ...mapActions({
      getStripeConfig: "stripeConfig/getStripeConfig",
      saveStripeConfig: "stripeConfig/saveStripeConfig",
    }),
    saveChanges: function () {
      this.saveStripeConfig(this.stripeConfig.data);
    },
  },
};
</script>

<template>
  <div class="client-notes">
    <div v-if="!isLoading && !isGettingAPts && templateOptions.length">
      <div class="d-flex noPrint flex-wrap">
        <div class="col-12 col-md-6">
          <select-control
            type="vertical"
            v-model="selectedTemplateIndex"
            :options="templateOptions"
            @input="updateAutoFillData"
            inputClass="light-shadow"
            required
            >Form Subject</select-control
          >
        </div>
        <div class="col-12 col-md-6">
          <select-control
            type="vertical"
            v-model="status"
            :options="statusOptions"
            inputClass="light-shadow"
            required
            >Form Status</select-control
          >
        </div>
      </div>
      <div class="d-flex noPrint flex-wrap">
        <div class="form-group pl-3 pr-3 col-sm-6 col-12">
          <label class="mb-0">Form Title</label>
          <input
            type="text"
            class="form-control light-shadow"
            v-model="name"
            required
          />
        </div>
        <div
          class="form-group pl-3 pr-3 col-sm-6 col-12"
          v-if="appointmentId == -1"
        >
          <select-control
            type="vertical"
            v-model="selectedAptId"
            :options="patientAptsOptions"
            @input="updateSelectedApt"
            inputClass="light-shadow"
            :required="status != '0'"
            >Event</select-control
          >
        </div>
      </div>
      <div class="print-section py-4 px-2">
        <table style="width: 100%" class="avoid-break-before">
          <thead>
            <tr>
              <td>
                <div class="header-space">&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="table-content">
                  <div
                    class="
                      header
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      v-if="
                        companyProfile.logo ||
                        (printSettings.show_second_logo_in_report &&
                          companyProfile.second_logo)
                      "
                      class="company-logo"
                      :src="
                        printSettings.show_second_logo_in_report &&
                        companyProfile.second_logo
                          ? companyProfile.second_logo
                          : companyProfile.logo
                      "
                      @load="logoLoaded"
                    />
                    <h2
                      class="
                        title
                        mt-3
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-center
                        text-center text-capitalize
                      "
                    >
                      {{ name }}
                    </h2>
                  </div>
                  <FormRenderer
                    v-if="configuration"
                    class="col-12"
                    :key="selectedTemplateIndex"
                    :form-configuration="configuration"
                    v-model="formInputData"
                    :baseURL="baseUrl"
                  />
                  <div class="footer mt-5">
                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="m-0">{{ companyProfile.name }}</p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_email_in_report"
                        >
                          {{ companyProfile.email }}
                        </p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_location_in_report"
                        >
                          {{ companyProfile.location }}
                        </p>
                      </div>
                      <div>
                        <p
                          class="m-0"
                          v-if="printSettings.show_phone_number_in_report"
                        >
                          {{ companyProfile.phone_number }}
                        </p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_lawyer_in_report"
                        >
                          {{ companyProfile.lawyer }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="m-0 w-100 mt-4 text-center h5 text-danger"
                      style="display: none"
                    >
                      {{ autoFilledData.timeZone }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div class="footer-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="note-header">
          <div class="row m-0">
            <div class="col-6">
              <p class="mb-0" v-if="printSettings.show_name_in_report">
                {{ patient.name }}
              </p>
              <p class="mb-0" v-if="printSettings.show_insurer_in_report">
                Insurer: {{ patient.insurer }}
              </p>
              <p class="mb-0" v-if="printSettings.show_claim_number_in_report">
                Claim Number: {{ patient.claim_number }}
              </p>
            </div>
            <div class="col-6 text-right">
              <p class="mb-0">
                Date of Report:
                {{
                  (autoFilledData || {}).sessionStartDate
                    | date("MMMM DD, YYYY")
                }}
              </p>
              <p class="mb-0" v-if="printSettings.show_date_of_loss_in_report">
                Date of Loss: {{ patient.injured_at | date("MMMM DD, YYYY") }}
              </p>
              <p class="mb-0" v-if="printSettings.show_date_of_birth_in_report">
                Date of Birth:
                {{ (patient.user || {}).birth_date | date("MMMM DD, YYYY") }}
              </p>
            </div>
          </div>
          <div></div>
        </div>
        <div class="note-footer text-center">Footer</div>
      </div>
      <div class="justify-content-center mt-4 d-flex noPrint">
        <button
          type="button"
          class="btn btn-lg btn-theme mr-3 noPrint"
          :disabled="
            (appointmentId == -1 && selectedAptId == -1 && status == '1') ||
            submitting
          "
          @click="createNote"
        >
          {{ submitting ? "Saving" : "Save" }}
        </button>
        <button
          type="button"
          class="btn btn-lg btn-danger noPrint"
          @click="print"
        >
          Print
        </button>
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-lg px-1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-cog"></i>
          </button>
          <div
            class="dropdown-menu print-menu"
            @click="$event.stopPropagation()"
          >
            <h3 class="mx-3 my-2">
              <i class="fas fa-cog mr-2"></i>Print settings
            </h3>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_second_logo_in_report"
                  v-model="printSettings.show_second_logo_in_report"
                  @change="logoReady = false"
                />
                <label
                  class="custom-control-label"
                  for="show_second_logo_in_report"
                  >Second Logo</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_name_in_report"
                  v-model="printSettings.show_name_in_report"
                />
                <label class="custom-control-label" for="show_name_in_report"
                  >Name</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_email_in_report"
                  v-model="printSettings.show_email_in_report"
                />
                <label class="custom-control-label" for="show_email_in_report"
                  >Email</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_location_in_report"
                  v-model="printSettings.show_location_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_location_in_report"
                  >Location</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_phone_number_in_report"
                  v-model="printSettings.show_phone_number_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_phone_number_in_report"
                  >Phone Number</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_claim_number_in_report"
                  v-model="printSettings.show_claim_number_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_claim_number_in_report"
                  >Claim Number</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_date_of_loss_in_report"
                  v-model="printSettings.show_date_of_loss_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_date_of_loss_in_report"
                  >Date of Loss</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_date_of_birth_in_report"
                  v-model="printSettings.show_date_of_birth_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_date_of_birth_in_report"
                  >Date of Birth</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_insurer_in_report"
                  v-model="printSettings.show_insurer_in_report"
                />
                <label class="custom-control-label" for="show_insurer_in_report"
                  >Insurer</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_funding_source_in_report"
                  v-model="printSettings.show_funding_source_in_report"
                />
                <label
                  class="custom-control-label"
                  for="show_funding_source_in_report"
                  >Fund Source</label
                >
              </div>
            </div>
            <div class="dropdown-item">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="show_lawyer_in_report"
                  v-model="printSettings.show_lawyer_in_report"
                />
                <label class="custom-control-label" for="show_lawyer_in_report"
                  >lawyer</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="isLoading || isGettingAPts" class="light-shadow" />
    <div v-if="!isLoading && !templateOptions.length">
      <alert class="light-shadow">Please add form template first</alert>
    </div>
    <loader v-if="gettingQuestionnaire"></loader>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isEmpty } from "lodash";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "CreateClinicalNote",
  created() {
    const eventsToFlush = [
      "renderer.validation_ok",
      "renderer.validation_failed",
    ];
    this.LSNoteForm =
      JSON.parse(window.localStorage.getItem("note_form")) || {};

    Object.keys(this.$formEvent._events).forEach((key) => {
      if (
        eventsToFlush.includes(key) &&
        this.$formEvent._events[key].length > 0
      ) {
        this.$formEvent._events[key] = [];
      }
    });
    this.$formEvent.$on("renderer.validation_ok", this.proceedAndSave);
    this.$formEvent.$on("renderer.validation_failed", this.showError);
    this.getCompanyProfile().then((companyData) => {
      if (companyData && isEmpty(this.printSettings)) {
        this.printSettings = {
          show_name_in_report: companyData.show_name_in_report,
          show_email_in_report: companyData.show_email_in_report,
          show_claim_number_in_report: companyData.show_claim_number_in_report,
          show_date_of_loss_in_report: companyData.show_date_of_loss_in_report,
          show_date_of_birth_in_report:
            companyData.show_date_of_birth_in_report,
          show_insurer_in_report: companyData.show_insurer_in_report,
          show_lawyer_in_report: companyData.show_lawyer_in_report,
          show_location_in_report: companyData.show_location_in_report,
          show_phone_number_in_report: companyData.show_phone_number_in_report,
          show_funding_source_in_report:
            companyData.show_funding_source_in_report,
        };
      }
    });
    this.getTemplates().then((res) => {
      if (res) {
        if (this.user) {
          if (this.appointmentId == -1) {
            this.isGettingAPts = true;
          }
          this.templates = res.filter((template) => template.type != "control");
          this.templates = this.templates.map((template) => {
            let templateString = JSON.stringify(template);
            templateString = templateString
              .replace(
                /http:\/\/\{getProvidersAPI\}/g,
                `${this.baseUrl}providers-basic-info`
              )
              .replace(
                /http:\/\/\{getProvidersAPIForPatient\}/g,
                `${this.baseUrl}providers-basic-info?patient_id=${this.patientId}`
              )
              .replace(
                /http:\/\/\{getManagersAPI\}/g,
                `${this.baseUrl}managers-basic-info`
              );
            return JSON.parse(templateString);
          });

          if (this.templates) {
            let mappedTemplates = this.templates.map((template, index) => {
              return {
                value: index,
                label: template.title,
                hidden: template.hidden,
              };
            });
            mappedTemplates = mappedTemplates.filter(
              (template) => !template.hidden
            );
            if (mappedTemplates.length) {
              if (!this.selectedTemplateIndex) {
                this.selectedTemplateIndex = mappedTemplates[0].value;
              }
              this.templateOptions = mappedTemplates;
            }
          }
          this.uploadFromLS().then((uploaded) => {
            this.getUserById(this.userId).then((res) => {
              if (res) {
                const authorData = {
                  authorName: `${res.last}, ${res.first}`,
                  authorFirstName: res.first,
                  authorLastName: res.last,
                  authorEmail: res.email,
                  authorStatus: res.status,
                  authorImage: res.profile_picture,
                };
                if (res.gender) {
                  authorData.authorGender =
                    res.gender == "1"
                      ? "Male"
                      : res.gender == "2"
                      ? "Female"
                      : "Other";
                }
                this.authorData = authorData;
                setTimeout(() => {
                  this.autoFilledData = Object.assign(
                    this.autoFilledData,
                    authorData
                  );
                  this.formInputData = Object.assign(
                    {},
                    uploaded
                      ? this.LSNoteForm.formInputData
                      : this.autoFilledData
                  );
                }, 500);
              }
            });
            this.getPatientById(this.patientId).then((res) => {
              if (res) {
                this.patient = res;
                const patientData = {
                  patientName: res.name,
                  patientFirstName: res.first,
                  patientMiddleName: res.middle,
                  patientLastName: res.last,
                  patientEmail: res.email,
                  patientPhone: res.phone_number,
                  patientClaimNumber: res.claim_number,
                  patientPolicyNumber: res.policy_number,
                  patientInjuryDate: res.injured_at,
                  patientFundingSource: res.funding_source,
                  patientStatus: res.status,
                  patientDiagnosis: res.diagnosis,
                  patientImage: res.profile_picture,
                };
                if (res.gender) {
                  patientData.patientGender =
                    res.gender == "1"
                      ? "Male"
                      : res.gender == "2"
                      ? "Female"
                      : "Other";
                }
                setTimeout(() => {
                  this.autoFilledData = Object.assign(
                    patientData,
                    this.authorData,
                    this.autoFilledData
                  );
                  this.formInputData = Object.assign(
                    {},
                    uploaded
                      ? this.LSNoteForm.formInputData
                      : this.autoFilledData
                  );
                }, 500);
              }
            });
            if (this.appointmentId != -1 && !uploaded) {
              this.getAptById(this.appointmentId).then((res) => {
                this.handleSelectedApt(res);
              });
              this.handleQuestionnaire(this.appointmentId);
            } else {
              this.getPatientApt();
            }
          });
        } else {
          this.templates = [];
        }
      }
    });
  },
  watch: {
    ...[
      "selectedTemplateIndex",
      "formInputData",
      "status",
      "name",
      "selectedAptId",
      "printSettings",
    ].reduce((acc, currentKey) => {
      acc[currentKey] = {
        handler() {
          this.saveValuesToLS();
        },
        deep: true,
      };
      return acc;
    }, {}),
  },
  beforeDestroy() {
    window.localStorage.removeItem("note_form");
  },
  data: () => ({
    autoFilledData: {
      timeZone: `Note: Time reference for this report is: GMT${
        new Date().toString().split("GMT")[1]
      }`,
    },
    authorData: {},
    gettingQuestionnaire: false,
    printSettings: {},
    submitting: false,
    logoReady: false,
    saved: false,
    patient: {},
    templates: [],
    isGettingAPts: false,
    LSNoteForm: undefined,
    selectedAptId: "-1",
    patientApts: [],
    name: "",
    templateOptions: [],
    selectedTemplateIndex: "0",
    formInputData: {},
    status: "0",
    statusOptions: [
      { value: "0", label: "Incomplete/Draft" },
      { value: "1", label: "Completed" },
    ],
  }),
  methods: {
    ...mapActions({
      getTemplates: "templates/getTemplates",
      createPatientNote: "templates/CreateClinicalNote",
      getUserById: "templates/getUserById",
      getAptById: "templates/getAptById",
      getQuestionnaireByAptId: "patients/symptoms/getQuestionnaireByAptId",
      getPatientById: "templates/getPatientById",
      getCompanyProfile: "settings/getCompanyProfile",
    }),
    showError: function () {
      Swal.fire({
        title: "Error",
        text: "Please fix the errors in the form.",
        icon: "error",
      });
    },
    proceedAndSave: function () {
      const selectedApt = this.selectedAptId == -1 ? null : this.selectedAptId;
      const data = {
        name: this.name,
        patient: this.patientId,
        user: this.patient.user_id,
        status: this.status,
        data: {
          ...this.formInputData,
          ...this.authorData,
          timeZone: this.autoFilledData.timeZone,
        },
        appointment:
          this.appointmentId != -1 ? this.appointmentId : selectedApt,
        form_template: this.templates[this.selectedTemplateIndex].id,
      };
      this.submitting = true;
      this.createPatientNote(data).then((res) => {
        this.submitting = false;
        if (res) {
          this.saved = true;
          window.localStorage.removeItem("note_form");
          this.$router.push({ name: "patients.notes" });
        }
      });
    },
    createNote: function () {
      if (
        (this.appointmentId == -1 &&
          this.selectedAptId == -1 &&
          this.status == "1") ||
        this.submitting
      ) {
        return;
      }
      if (!(this.name || "").trim()) {
        Swal.fire({
          title: "Error",
          text: "Note title is missing",
          icon: "error",
        });
        return;
      }
      if (
        this.formInputData.bodyLocations &&
        this.formInputData.bodyLocations.length &&
        (!this.formInputData.painTime || this.formInputData.painTime == "null")
      ) {
        Swal.fire({
          title: "Error",
          text: "Pain time is missing",
          icon: "error",
        });
        return;
      }
      if (this.formInputData.noClientPain) {
        this.formInputData.clientPainRate = -1;
      }
      if (this.formInputData.noClientMood) {
        this.formInputData.clientMoodRate = -1;
      }
      if (this.formInputData.noClientFatigue) {
        this.formInputData.clientFatigueRate = -1;
      }
      if (this.formInputData.noClientHeadaches) {
        this.formInputData.clientHeadachesRate = -1;
      }
      this.$formEvent.$emit("renderer.run_validation");
    },
    saveValuesToLS: function () {
      try {
        window.localStorage.setItem(
          "note_form",
          JSON.stringify({
            status: this.status,
            name: this.name,
            selectedTemplateIndex: this.selectedTemplateIndex,
            printSettings: this.printSettings,
            formInputData: this.formInputData,
            selectedAptId: this.selectedAptId,
          })
        );
      } catch (err) {
        //eslint-disable-next-line
        console.log(err);
      }
    },
    uploadFromLS: function () {
      try {
        const noteForm = this.LSNoteForm;
        if (
          !isEmpty(noteForm) &&
          (noteForm.name != "" ||
            noteForm.selectedAptId != "-1" ||
            noteForm.selectedTemplateIndex != "0" ||
            noteForm.status != "0")
        ) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-black ml-4",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          return swalWithBootstrapButtons
            .fire({
              title: "Did you lose your data?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, restore!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.selectedTemplateIndex =
                  noteForm.selectedTemplateIndex || "0";
                this.status = noteForm.status || "0";
                this.name = noteForm.name || "";
                this.selectedAptId = noteForm.selectedAptId || "-1";
                this.printSettings = noteForm.printSettings || {};
                this.formInputData = Object.assign(
                  {},
                  noteForm.formInputData || {}
                );
                window.localStorage.removeItem("note_form");
                return Promise.resolve(true);
              } else {
                window.localStorage.removeItem("note_form");
                this.LSNoteForm = undefined;
                return Promise.resolve(false);
              }
            });
        } else {
          return Promise.resolve(false);
        }
      } catch (e) {
        //eslint-disable-next-line
        console.log(e);
        return Promise.resolve(false);
      }
    },
    logoLoaded: function () {
      this.logoReady = true;
    },
    handleQuestionnaire: function (appointmentId) {
      this.gettingQuestionnaire = true;
      this.getQuestionnaireByAptId(appointmentId).then((res) => {
        this.gettingQuestionnaire = false;
        if (res) {
          const questionnaireData = {
            frustration: `${res.frustration}`,
            difficulties_with_problem_solving: `${res.difficulties_with_problem_solving}`,
            dizziness: `${res.dizziness}`,
            difficulties_with_sensory_stimuli: `${res.difficulties_with_sensory_stimuli}`,
            memory: `${res.memory}`,
            verbal_aggression: `${res.verbal_aggression}`,
            physical_aggression: `${res.physical_aggression}`,
            socially_inappropriate_behavior: `${res.socially_inappropriate_behavior}`,
            sexually_inappropriate_behavior: `${res.sexually_inappropriate_behavior}`,
            painTime: `${res.time_pain_client}`,
            sleepHours: `${res.hours_client}`,
            anxietyReported: `${res.anxiety_client}`,
            clientPainRate: res.pain_rate_client,
            noClientPain: res.pain_rate_client == -1,
            clientFatigueRate: res.fatigue_rate_client,
            noClientFatigue: res.fatigue_rate_client == -1,
            clientMoodRate: res.mood_rate_client,
            noClientMood: res.mood_rate_client == -1,
            clientHeadachesRate: res.headaches_rate_client,
            noClientHeadaches: res.headaches_rate_client == -1,
            activityCategory: res.activity_categories.map((item) => item.id),
            bodyLocations: res.body_locations,
            providerPainRate: res.pain_rate_provider,
            providerFatigueRate: res.fatigue_rate_provider,
            providerMoodRate: res.mood_rate_provider,
            providerHeadachesRate: res.headaches_rate_provider,
          };

          this.autoFilledData = Object.assign(
            this.autoFilledData,
            questionnaireData
          );
          this.formInputData = Object.assign({}, this.autoFilledData);
        }
      });
    },
    print: function () {
      const interval = setInterval(() => {
        if (
          this.logoReady ||
          !this.companyProfile.logo ||
          (this.printSettings.show_second_logo_in_report &&
            !this.companyProfile.second_logo)
        ) {
          clearInterval(interval);
          window.print();
        }
      }, 500);
    },
    updateAutoFillData: function () {
      setTimeout(() => {
        this.formInputData = this.autoFilledData;
      }, 500);
    },
    updateSelectedApt: function () {
      const selectedApt = (this.patientApts || []).filter(
        (item) => item.id == this.selectedAptId
      )[0];
      this.handleSelectedApt(Object.assign({}, selectedApt));
      this.handleQuestionnaire(this.selectedAptId);
    },
    handleSelectedApt: function (res) {
      // const subHourlyServices = res.services.filter(
      //   (serv) => serv.parent_service_id && serv.pivot?.unit == "HR"
      // );
      // const totalSubHours = subHourlyServices.reduce(
      //   (sum, { pivot }) => sum + pivot?.quantity,
      //   0
      // );
      const start_time = dayjs(res.start_time).utc();
      let end_time = dayjs(res.end_time)
        // .add(totalSubHours * 60, "minute")
        .utc();

      const startHours = start_time.hour() || 12;
      const endHours = end_time.hour() || 12;
      const startHoursAMPM = startHours > 12 ? startHours - 12 : startHours;
      const endHoursAMPM = endHours > 12 ? endHours - 12 : endHours;
      const aptData = {
        sessionStartDate: start_time.format(),
        sessionEndDate: end_time.format(),
        sessionReason: res.reason,
        sessionMemo: res.memo,
        sessionStatus: res.status,
        sessionType: res.type,
        sessionStartHours:
          startHoursAMPM > 9 ? `${startHoursAMPM}` : `0${startHoursAMPM}`,
        sessionStartMinutes:
          start_time.minute() > 9
            ? `${start_time.minute()}`
            : `0${start_time.minute()}`,
        sessionStartAMPM: startHours > 12 ? "PM" : "AM",
        sessionEndHours:
          endHoursAMPM > 9 ? `${endHoursAMPM}` : `0${endHoursAMPM}`,
        sessionEndMinutes:
          end_time.minute() > 9
            ? `${end_time.minute()}`
            : `0${end_time.minute()}`,
        sessionEndAMPM: endHours > 12 ? "PM" : "AM",
      };
      aptData.sessionStartTime = `${aptData.sessionStartHours}:${aptData.sessionStartMinutes} ${aptData.sessionStartAMPM}`;
      aptData.sessionEndTime = `${aptData.sessionEndHours}:${aptData.sessionEndMinutes} ${aptData.sessionEndAMPM}`;
      if (res.services && res.services.length) {
        const service = res.services[0];
        aptData.serviceDescription = service.description;
        aptData.serviceName = service.name;
        aptData.serviceTaxable = service.taxable;
        aptData.serviceCatastrophic = service.catastrophic;
        aptData.serviceProv = service.prov;
        aptData.serviceRate = service.rate;
        aptData.serviceUnit = service.unit;
      }
      this.autoFilledData = Object.assign(this.autoFilledData, aptData);
      this.formInputData = Object.assign({}, this.autoFilledData);
    },
    getPatientApt: function () {
      this.$http
        .get(`patients/${this.patientData.user_id}/appointments`)
        .then((res) => {
          this.isGettingAPts = false;
          if (res && res.data && res.data.data) {
            this.patientApts = res.data.data;
          }
        })
        .catch(() => {
          this.isGettingAPts = false;
        });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.templates.isLoading,
      user: (state) => state.auth.user,
      companyProfile: (state) => state.settings.companyProfile,
      patientData: (state) => state.patients.patient,
      baseUrl: (state) => state.appConfig.api,
    }),
    configuration: function () {
      if (this.templates && this.templates.length) {
        return Object.assign(
          {},
          this.templates[this.selectedTemplateIndex].body
        );
      }
      return null;
    },
    patientId: function () {
      return this.$route.params.id;
    },
    userId: function () {
      return this.$route.params.userId;
    },
    appointmentId: function () {
      return this.$route.params.appointmentId;
    },
    selectedTemplate: function () {
      return this.templates[this.selectedTemplateIndex];
    },
    patientAptsOptions: function () {
      if (this.patientApts.length) {
        const options = this.patientApts.map((apt) => {
          let label = "";
          let serviceName = "";
          if (apt.services.length) {
            serviceName = apt.services[0].name;
          }
          const subHourlyServices = apt.services.filter(
            (serv) => serv.parent_service_id && serv.pivot?.unit == "HR"
          );
          const subServicesHours = subHourlyServices.reduce(
            (sum, { pivot }) => sum + pivot?.quantity,
            0
          );
          const subMinutes =
            (subServicesHours - parseInt(subServicesHours)) * 60;
          label = dayjs(apt.start_time).utc().format("MMM D, YYYY hh:mm A");
          label = `${label} - ${dayjs(apt.end_time)
            .utc()
            .format("MMM D, YYYY hh:mm A")}`;
          if (serviceName) {
            label = `${label} (${serviceName})`;
          }
          if (subServicesHours) {
            label = `${label} +${parseInt(subServicesHours)}h${
              subMinutes > 0 ? " " + subMinutes + "m" : ""
            }`;
          }
          return {
            value: apt.id,
            label,
          };
        });

        options.push({
          value: "-1",
          label: "Select Event",
        });
        return options.reverse();
      }
      return [
        {
          value: "-1",
          label: "Select Event",
        },
      ];
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved) {
      return next();
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-black ml-4",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    return swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Any changes you made may not be saved.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, leave!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          return next();
        }
        return next(false);
      });
    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
};
</script>
<style scoped lang="scss">
.loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.print-menu {
  max-height: 350px;
  overflow-y: auto;
}
.print-section {
  border: 1px solid #c1c4d0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    overflow: auto;
  }
}
.company-logo {
  max-width: 30%;
  position: absolute;
  left: 0;
  max-height: 90%;
}
.title {
  width: 40%;
  margin: 0 auto;
}
.header {
  position: relative;
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  min-height: 150px;
}

.footer {
  color: gray;
  border-top: 1px solid lightgray;
  padding-top: 20px;
}
</style>
<style>
.note-header,
.header-space,
.note-footer,
.footer-space {
  height: 100px;
  display: none;
  z-index: -1;
}
.note-header,
.note-footer {
  position: fixed;
  width: 100%;
  left: 0;
}

.note-header {
  top: 0;
}
.note-footer {
  bottom: 0;
}

@media print {
  .main-container {
    margin-top: 0;
  }
  .note-header,
  .header-space {
    display: block;
  }
  .note-footer,
  .footer-space {
    display: none;
  }
  .print-section {
    border: 0 !important;
    background-color: transparent !important;
  }
  .vue-form-renderer .control-view,
  .vue-form-renderer .control-view-wrapper {
    break-inside: avoid;
    page-break-inside: avoid;
    z-index: 9;
  }
}
.table-content {
  page-break-inside: avoid;
}
</style>

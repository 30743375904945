<template>
  <div class="user-type-navbar">
    <ul class="navbar-nav mr-auto links-navbar-nav">
      <li class="nav-item">
        <router-link
          to="/dashboard"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Dashboard</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          to="/calendar"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Calendar</router-link
        >
      </li>
      <li
        class="nav-item"
        v-if="$can({ key: 'clients', expectedPermission: 'view' })"
      >
        <router-link
          :to="{ name: 'patients' }"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Clients</router-link
        >
      </li>
      <li
        class="nav-item"
        v-if="$can({ key: 'providers', expectedPermission: 'view' })"
      >
        <router-link
          :to="{ name: 'providers' }"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Providers</router-link
        >
      </li>
      <UnreadAlert />
      <li class="nav-item">
        <router-link
          :to="{ name: 'rooms' }"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          Meeting Rooms
        </router-link>
      </li>
      <li class="nav-item dropdown">
        <a
          href="#"
          class="nav-link dropdown-toggle u-mr-8"
          data-toggle="dropdown"
          >Payments</a
        >
        <div class="dropdown-menu">
          <router-link
            to="/stripe-config"
            class="dropdown-item u-mr-8"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
          >
            Stripe Configuration
          </router-link>
          <router-link
            to="/creditCards"
            v-if="!stripeConfig?.isloading && stripeConfig?.data?.is_enabled"
            class="dropdown-item u-mr-8"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
          >
            Credit Cards
          </router-link>
          <router-link
            v-if="!stripeConfig?.isloading && stripeConfig?.data?.is_enabled"
            to="/transactions"
            class="dropdown-item u-mr-8"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
          >
            Transactions
          </router-link>
        </div>
      </li>
      <pending-booking-requests />
      <li
        class="nav-item dropdown"
        v-if="
          $can({ key: 'payrollReport', expectedPermission: 'view' }) ||
          $can({ key: 'billingReport', expectedPermission: 'view' }) ||
          $can({ key: 'maxHoursReport', expectedPermission: 'view' })
        "
      >
        <a
          href="#"
          class="nav-link dropdown-toggle u-mr-8"
          data-toggle="dropdown"
          >Reports</a
        >
        <div class="dropdown-menu">
          <router-link
            v-if="$can({ key: 'payrollReport', expectedPermission: 'view' })"
            to="/admin/reports/payroll"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Payroll Report</router-link
          >
          <router-link
            v-if="$can({ key: 'billingReport', expectedPermission: 'view' })"
            to="/admin/reports/billing"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Billing Report</router-link
          >
          <router-link
            v-if="$can({ key: 'maxHoursReport', expectedPermission: 'view' })"
            to="/admin/reports/maxhours"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Budget Report</router-link
          >
          <router-link
            to="/admin/reports/hcai"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >HCAI Reports</router-link
          >
        </div>
      </li>
      <li class="nav-item dropdown">
        <a
          href="#"
          class="nav-link dropdown-toggle u-mr-8"
          data-toggle="dropdown"
          >Admin</a
        >
        <div class="dropdown-menu">
          <router-link
            to="/admin/users"
            class="dropdown-item"
            v-if="
              user &&
              !user.isManagingAdmin &&
              $can({ key: 'adminUser', expectedPermission: 'view' })
            "
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Admin Users</router-link
          >
          <router-link
            to="/admin/users-availability"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Users Availability</router-link
          >
          <router-link
            v-if="$can({ key: 'clinics', expectedPermission: 'view' })"
            to="/admin/clinics"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Clinics</router-link
          >
          <router-link
            v-if="$can({ key: 'classes', expectedPermission: 'view' })"
            to="/admin/classes"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Classes</router-link
          >

          <router-link
            v-if="user && !user.isManagingAdmin"
            to="/admin/settings"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Settings</router-link
          >

          <router-link
            v-if="$can({ key: 'services', expectedPermission: 'view' })"
            to="/admin/services"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Services</router-link
          >
          <router-link
            v-if="$can({ key: 'services', expectedPermission: 'view' })"
            to="/admin/treatments"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Treatments</router-link
          >
          <router-link
            v-if="$can({ key: 'professions', expectedPermission: 'view' })"
            to="/admin/professions"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Professions</router-link
          >
          <router-link
            to="/admin/referral-sources"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Referral Sources</router-link
          >
          <router-link
            to="/admin/locations"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Locations</router-link
          >
          <router-link
            to="/admin/documents"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Documents</router-link
          >
          <router-link
            to="/admin/clients-import"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Import Clients</router-link
          >
          <router-link
            to="/admin/providers-import"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Import Providers</router-link
          >
          <router-link
            to="/templates"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Form Templates</router-link
          >
          <router-link
            to="/clients-contacts"
            class="dropdown-item"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            >Clients Contacts</router-link
          >
        </div>
      </li>
      <profile-nav />
    </ul>
    <ul class="navbar-nav icons-navbar-nav search-task-nav">
      <li class="nav-item position-relative search-nav-item desktop-only-force">
        <label
          :data-state="searchState"
          for="search"
          class="jump-label"
          :title="searchState == 'open' ? '' : 'Jump to Anyone...'"
        >
          <input
            type="text"
            placeholder="Jump to Anyone..."
            @click="searchState = 'open'"
            v-model="searchKey"
            @blur="searchBlured"
          />
          <i
            :class="searchState == 'open' ? 'fa fa-times' : 'fa fa-search'"
            aria-hidden="true"
          ></i>
          <ul class="search-result_users">
            <li
              class="text-center p-3 h3 m-0"
              v-if="!loading && !filteredUsers.length && searchKey"
            >
              No users found.
            </li>
            <li
              class="text-center p-3 h3 m-0"
              v-if="!loading && !filteredUsers.length && !searchKey"
            >
              No users to search for.
            </li>
            <alert v-if="loading" class="m-0" />
            <li
              v-for="userItem in filteredUsers"
              :key="userItem.username"
              @click="navigateToUser(userItem)"
              class="pointer-cursor"
            >
              <div
                class="
                  d-flex
                  justify-content-between
                  jump-list-item
                  align-items-center
                "
              >
                <div
                  class="jump-list-name"
                  :class="`${
                    userItem.status != 'INACTIVE' ? '' : 'text-danger'
                  }`"
                >
                  {{ userItem.name }}
                </div>
                <div
                  class="jump-list-role text-bold text-primary"
                  :class="`${
                    userItem.status != 'INACTIVE'
                      ? 'text-primary'
                      : 'text-danger'
                  }`"
                >
                  {{ getRoleName(parseInt(userItem.roleId)) }}
                </div>
                <div
                  class="delete-user-icon text-danger"
                  v-if="
                    user &&
                    user.isAdmin &&
                    user.email == 'eng.karam.eqhash@gmail.com'
                  "
                  @click.stop="deleteUser(userItem.user_id)"
                >
                  <i class="fa fa-trash" />
                </div>
              </div>
            </li>
          </ul>
        </label>
      </li>

      <li class="nav-item nav-theme-color-item mr-2 desktop-only-force">
        <div
          class="nav-item theme-color-item pointer-cursor"
          @click="navigateToProfile"
        >
          <div class="nav-item create-task-item create-task-modal">
            <i class="far fa-user fa-lg text-main fa-19" title="My Profile" />
          </div>
        </div>
      </li>
      <li class="nav-item nav-create-task-item desktop-only-force">
        <div class="nav-create-task">
          <div class="nav-item create-task-item create-task-modal">
            <i
              class="far fa-edit pointer-cursor text-main"
              title="Create Task"
              @click="openCreateTaskModal"
            />
            <sidebar
              :toggle="openModal"
              @close="closeCreateTaskModal"
              class="create-task-modal"
            >
              <Create
                :insideModal="true"
                @saved="taskSaved"
                :noRedirect="true"
                :key="Math.random()"
                v-if="openModal"
              ></Create>
            </sidebar>
          </div>
        </div>
      </li>
      <li class="nav-item nav-theme-color-item pointer-cursor">
        <div class="nav-item theme-color-item" @click="openThemeModal">
          <img class="pallete-img" :src="palleteImg" title="Theme" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { default as ProfileNav } from "./Profile";
import { default as Create } from "../../views/tasks/Create";
import { default as UnreadAlert } from "../UnreadAlert.vue";
import { mapState, mapActions } from "vuex";
import PendingBookingRequests from "@/components/PendingBookingRequests.vue";

export default {
  components: {
    ProfileNav,
    Create,
    UnreadAlert,
    PendingBookingRequests,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allUsers: (state) => state.security.usersWithRoles,
      stripeConfig: (state) => state.stripeConfig,
    }),
    filteredUsers: function () {
      if (!this.searchKey) {
        return this.allUsers;
      }
      return (this.allUsers || []).filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
      );
    },
  },
  data() {
    return {
      openModal: false,
      searchState: "close",
      searchKey: "",
      loading: true,
      palleteImg: require("@/assets/img/icons/pallete.png"),
    };
  },
  mounted() {
    this.getAllUsers().then(() => {
      this.loading = false;
    });
    this.getStripeConfig();
  },
  methods: {
    ...mapActions({
      getAllUsers: "security/getUsersWithRoles",
      getStripeConfig: "stripeConfig/getStripeConfig",
    }),
    searchBlured: function () {
      setTimeout(() => {
        this.searchState = "close";
        this.searchKey = "";
      }, 500);
    },
    navigateToProfile: function () {
      this.$router.push({ path: "/admin/profile" });
    },
    navigateToUser: function (userItem) {
      if (userItem.roleId == 1 || userItem.roleId == 5) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/admin/users/${userItem.id}` });
        }, 500);
      } else if (userItem.roleId == 2) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/providers/${userItem.id}` });
        }, 500);
      } else {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/clients/${userItem.id}` });
        }, 500);
      }
    },
    openCreateTaskModal: function () {
      this.openModal = true;
    },
    closeCreateTaskModal: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    openThemeModal: function () {
      this.$modal.show("theme-modal");
    },
    taskSaved: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    getRoleName: function (roleId) {
      let roleName;
      switch (roleId) {
        case 1:
          roleName = "(Admin)";
          break;
        case 2:
          roleName = "(Provider)";
          break;
        case 5:
          roleName = "(Manager)";
          break;
        default:
          roleName = "(Client)";
          break;
      }
      return roleName;
    },
    deleteUser: function (id) {
      this.$store.dispatch("Profile/deleteUser", id);
    },
  },
};
</script>

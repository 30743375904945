<template>
  <div>
    <form id="payment-form">
      <label class="note-info"> Please enter your Card information </label>
      <div id="card-element">
        <!-- Stripe.js injects the card input here -->
      </div>
      <div class="buttons">
        <button
          :disabled="isSaving"
          class="btn btn-outline-blue mb-3 mb-lg-0"
          @click.prevent="submit"
        >
          <span class="spinner-border spinner-border-sm" v-if="isSaving"></span>
          <span v-else> Add Credit Card </span>
        </button>
        <button
          v-if="src"
          class="btn btn-link ml-2"
          @click.prevent="navigateBack"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    const elements = window.stripe.elements();
    const cardElement = elements?.create("card", {
      style: {
        base: {
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "2.2",
        },
      },
    });
    return {
      cardElement,
    };
  },
  mounted() {
    this.cardElement.mount("#card-element");
  },
  computed: {
    ...mapState({
      isSaving: (state) => state.creditCards.isSaving,
      patient: (state) => state.patients.patient,
    }),
    src: function () {
      return this.$route.query.src;
    },
  },
  methods: {
    ...mapActions({
      storeCard: "creditCards/storeCard",
      setIsSaving: "creditCards/setIsSaving",
    }),
    async submit() {
      try {
        this.setIsSaving(true);
        const { paymentMethod } = await window.stripe.createPaymentMethod({
          type: "card",
          card: this.cardElement,
        });
        this.tokenCreated(paymentMethod);
      } catch (err) {
        Swal.fire({
          title: "Error",
          text:
            ((err || {}).error || {}).message ||
            "Please make sure card info is filled properly",
          icon: "error",
        });
      }
      this.setIsSaving(false);
    },
    tokenCreated(paymentMethod) {
      this.storeCard({
        payment_method_id: paymentMethod.id,
        user_id: this.patient.user_id,
        patient_id: this.patient.id,
      });
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 20px;
}

.note-info {
  font-size: 17px;
  margin-bottom: 10px;
}
#card-element {
  background-color: white;
  padding-left: 15px;
}
</style>

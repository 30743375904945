<template>
  <li class="nav-item">
    <router-link
      :to="{ name: 'booking-requests' }"
      class="nav-link u-mr-8"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
    >
      Booking Requests
      <span v-show="showBadge" class="badge badge-pill badge-theme ml-1">
        {{ pendingBookingRequestsCount }}
      </span>
    </router-link>
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  mounted() {
    const vm = this;
    this.getPendingBookingRequestsCount();
    window.Echo.encryptedPrivate("booking-requests").listen(
      "BookingRequestStatusUpdated",
      (e) => {
        if (vm.loggedInUser) {
          if (vm.loggedInUser.isAdmin || vm.loggedInUser.isManagingAdmin) {
            if (e?.status === "pending") {
              vm.updatePendingBookingRequestsCount(1);
            } else {
              vm.updatePendingBookingRequestsCount(-1);
            }
          } else if (
            vm.loggedInUser.isProvider &&
            e?.providers &&
            e?.providers?.includes(vm.loggedInUser.id)
          ) {
            if (e?.status === "pending") {
              vm.updatePendingBookingRequestsCount(1);
            } else {
              vm.updatePendingBookingRequestsCount(-1);
            }
          } else if (e.user_id == vm.loggedInUser.id) {
            if (e?.status === "pending") {
              vm.updatePendingBookingRequestsCount(1);
            } else {
              vm.updatePendingBookingRequestsCount(-1);
            }
          }
        }
      }
    );
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      pendingBookingRequestsCount: (state) =>
        state.booking.pendingBookingRequestsCount,
    }),
    showBadge: function () {
      return this.pendingBookingRequestsCount;
    },
  },
  methods: {
    ...mapActions({
      getPendingBookingRequestsCount: "booking/getPendingBookingRequestsCount",
      updatePendingBookingRequestsCount:
        "booking/updatePendingBookingRequestsCount",
    }),
  },
};
</script>
<style lang="scss" scoped>
.badge-theme {
  background-color: var(--theme-color);
  color: var(--btn-text-color);
}
</style>

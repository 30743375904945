<template>
  <div v-if="!stripeConfig.isloading && stripeConfig?.data?.is_enabled">
    <alert class="my-4" v-if="transactions.isLoading" />
    <alert
      class="my-4"
      v-if="!transactions.isLoading && transactions?.data?.length == 0"
      :hideLoader="true"
      >No Transactions</alert
    >
    <div
      class="
        transaction-body
        card
        border-0
        overflow-auto
        mobile-tablet-no-card
        p-2 p-md-0
      "
      v-if="!transactions.isLoading && transactions?.data?.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Created at</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Reference Number</th>
            <th>Card Type</th>
            <th>Card Ending In</th>
            <th>Owner Name</th>
            <th>Payment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions?.data" :key="transaction.id">
            <td>
              <b class="mobile-tablet-only mr-2">Created at</b>
              <span>{{ prettyDate(transaction.created_at) }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status</b>
              <span
                class="text-capitalize"
                :class="
                  transaction.status === 'succeeded'
                    ? 'text-success'
                    : 'text-danger'
                "
                >{{ transaction.status }}</span
              >
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Amount</b>
              <span>{{ transaction.amount }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Reference Number</b>
              <span>{{ transaction.id }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Card Type</b>
              <span>{{ transaction.card?.brand }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Card Ending in</b>
              <span>{{ transaction.card_last4 }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Owner Name</b>
              <span>{{ transaction.owner?.name }}</span>
            </td>
            <td>
              <div class="mobile-tablet-only mr-2">Payment</div>
              <span>
                <a
                  href="#"
                  @click.prevent="() => openTransactionModal(transaction)"
                >
                  Payment #{{ transaction.id }}
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TransactionDetailsModal />
  </div>
  <div
    class="d-flex align-items-center justify-content-center redirect-to-stripe"
    v-else
  >
    <span class="mr-2">Please enable Stripe from the following page</span>
    <router-link
      to="/stripe-config"
      class="nav-link p-0"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      >Stripe Configuration</router-link
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import helpers from "@/utils/helpers";
import TransactionDetailsModal from "./TransactionDetailsModal.vue";
export default {
  name: "Transactions",
  components: {
    TransactionDetailsModal,
  },
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      transactions: (state) => state.transactions,
      patient: (state) => state.patients.patient,
      stripeConfig: (state) => state.stripeConfig,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.getTransactions(
      this.$route.meta.allUsers ? undefined : this.patient?.user_id
    );
  },
  methods: {
    ...mapActions({
      getTransactions: "transactions/getTransactions",
      selectPrintTransaction: "transactions/selectPrintTransaction",
    }),
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
    openTransactionModal: function (transaction) {
      this.selectPrintTransaction(transaction);
      this.$modal.show("transaction-modal");
    },
  },
};
</script>

<template>
  <div class="container-lg booking-container">
    <div
      v-if="loggedInUser"
      class="
        card
        theme-card
        mb-2
        px-md-4 px-3
        py-3
        d-flex
        flex-row
        align-items-center
      "
    >
      <img
        :src="userAvatar"
        class="rounded-circle mr-3"
        width="50"
        height="50"
        alt="User Profile Picture"
      />
      <div class="booking-container__user-name">
        <span class="font-weight-normal">Welcome, </span>
        {{ loggedInUser.first }}
      </div>
    </div>
    <div class="card theme-card booking-container__header px-md-4 px-3 py-3">
      <div class="booking-container__title">
        <span v-if="!loggedInUser">Welcome to the </span>Service Booking Portal
      </div>
      <div class="booking-container__subtitle">
        Quickly and conveniently book the services you need in just two simple
        steps. First, select your session details: choose a treatment and a
        preferred date and time. Next, provide your personal information to
        complete the booking. Our streamlined interface ensures an effortless
        scheduling process, saving you time and hassle.
      </div>
    </div>
    <form @submit.prevent="verifyBookingRequestHelper" validate>
      <div class="card theme-card px-4 py-3 booking-container__body">
        <div v-if="activeStep === 1">
          <h1 class="mt-3 booking-container__title">Session Details</h1>
          <div class="col-12 mb-3">
            <label class="col-12 font-weight-bold py-2 pl-0 border-bottom">
              Select a treatment to book
            </label>
            <div class="d-flex flex-column">
              <alert
                v-if="treatmentsLoading"
                class="py-8-px light-shadow"
              ></alert>
              <div class="treatments-scroll-container text-white">
                <ul
                  v-if="!treatmentsLoading"
                  class="d-flex flex-column treatments-list"
                >
                  <li
                    v-for="treatment in treatments"
                    :key="treatment.id"
                    class="treatment-card"
                    @click="dataToSubmit.treatment_id = treatment.id"
                    :class="{
                      active: dataToSubmit.treatment_id === treatment.id,
                    }"
                  >
                    <strong>
                      {{ treatment.name }}
                      ({{ treatment.readable_duration }})
                    </strong>
                    - {{ treatment.readable_price }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="showAvailabilityCalendar"
            class="col-12 mb-3 d-flex flex-column"
            ref="bookingCalendarContainer"
          >
            <label class="col-12 font-weight-bold py-2 px-0 border-bottom">
              Select your preferred date and time
            </label>
            <div class="d-flex flex-md-row flex-column">
              <div class="col-md-6 col-12 pr-md-3 px-0 mb-md-0 mb-3">
                <v-date-picker
                  class="h-100"
                  v-model="selectedDate"
                  mode="date"
                  :min-date="new Date()"
                  @dayclick="onDayClick"
                  is-expanded
                />
              </div>
              <div class="d-flex flex-column col-12 col-md-6 px-md-3 px-0">
                <div>
                  <label class="col-12 font-weight-bold pt-2 px-0">
                    Booking date
                  </label>
                  <div class="booking-date-data">
                    {{ formattedSelectedDate }}
                  </div>
                </div>
                <div class="mt-3">
                  <label
                    class="col-12 font-weight-bold px-0 d-flex flex-column"
                  >
                    Booking time
                    <small class="text-muted">
                      Eastern Time (US & Canada)
                    </small>
                  </label>
                  <div class="booking-date-data mb-3">
                    Only available times are shown
                  </div>
                  <div class="form-group d-flex flex-md-row flex-column mb-3">
                    <alert
                      v-if="treatmentAvailabilityLoading"
                      class="col-12 mb-0 time-dropdown"
                    ></alert>
                    <alert
                      v-else-if="availableHours.length === 0"
                      :hideLoader="true"
                      class="col-12 mb-0 time-dropdown"
                    >
                      No available hours
                    </alert>
                    <select
                      v-else
                      v-model="selectedHour"
                      class="form-control time-dropdown"
                      @change="selectedTime = null"
                    >
                      <option :value="null" selected>Booking hour</option>
                      <option
                        v-for="option in availableHours"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group d-flex flex-md-row flex-column mb-0">
                    <alert
                      v-if="treatmentAvailabilityLoading"
                      class="col-12 mb-0 time-dropdown"
                    ></alert>
                    <alert
                      v-else-if="availableTimes.length === 0"
                      :hideLoader="true"
                      class="col-12 mb-0 time-dropdown"
                    >
                      No available times
                    </alert>
                    <select
                      v-else
                      v-model="selectedTime"
                      class="form-control time-dropdown"
                    >
                      <option :value="null" selected>Exact time</option>
                      <option
                        v-for="option in availableTimes"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeStep === 2">
          <h1 class="mt-3 font-weight-bold">Personal Information</h1>
          <div
            v-if="!loggedInUser"
            class="mb-3 d-flex flex-md-row flex-column align-items-center"
          >
            <div class="mr-md-3 flex-grow-1 col-md-auto col-12 px-md-3 px-0">
              <label class="col-12 font-weight-bold pt-2 pl-0">
                First Name
              </label>
              <input
                v-model="dataToSubmit.first_name"
                type="text"
                class="form-control light-shadow"
                required
              />
            </div>
            <div class="flex-grow-1 col-md-auto col-12 px-md-3 px-0">
              <label class="col-12 font-weight-bold pt-2 pl-0">Last Name</label>
              <input
                v-model="dataToSubmit.last_name"
                type="text"
                class="form-control light-shadow"
                required
              />
            </div>
          </div>
          <div v-if="!loggedInUser" class="col-12 mb-3 px-md-3 px-0">
            <label class="col-12 font-weight-bold pt-2 pl-0">Email</label>
            <input
              v-model="dataToSubmit.email"
              type="email"
              class="form-control light-shadow"
              required
            />
          </div>
          <div v-if="!loggedInUser" class="col-12 mb-3 px-md-3 px-0">
            <label class="col-12 font-weight-bold pt-2 pl-0"
              >Phone Number</label
            >
            <input
              v-model="dataToSubmit.phone_number"
              type="tel"
              class="form-control light-shadow"
              required
            />
          </div>
          <div
            v-if="!loggedInUser"
            class="mb-3 d-flex flex-md-row flex-column align-items-center"
          >
            <div class="mr-md-3 flex-grow-1 col-md-auto col-12 px-md-3 px-0">
              <label class="col-12 font-weight-bold pt-2 pl-0">
                Birth Date
              </label>
              <v-date-picker
                v-model="dataToSubmit.birth_date"
                :update-on-input="false"
                :first-day-of-week="7"
                :masks="masks"
                mode="date"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    :value="inputValue"
                    v-on="inputEvents"
                    class="form-control light-shadow"
                    required
                  />
                </template>
              </v-date-picker>
            </div>
            <div class="flex-grow-1 col-md-6 col-12 px-md-3 px-0">
              <label class="col-12 font-weight-bold pt-2 pl-0">Gender</label>
              <select
                v-model="dataToSubmit.gender"
                class="form-control"
                required
              >
                <option
                  v-for="option in genderOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 mb-3 px-md-3 px-0">
            <label class="col-12 font-weight-bold pt-2 pl-0">
              Additional Notes
            </label>
            <textarea
              v-model="dataToSubmit.additional_notes"
              class="form-control light-shadow"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between">
          <button
            v-if="activeStep === 2"
            class="mb-3 btn btn-theme btn-md-lg d-flex align-items-center"
            @click.prevent="handleStep(-1)"
            type="button"
          >
            <i class="fas fa-angles-left display-6"></i>
            <span class="ml-2 display-6">Back</span>
          </button>
          <div class="w-100 d-flex justify-content-end">
            <button
              v-if="activeStep === 1"
              :class="{
                'mb-3 btn btn-black btn-md-lg d-flex align-items-center': true,
                disabled: nextButtonDisabled,
              }"
              @click.prevent="!nextButtonDisabled && handleStep(1)"
              type="button"
            >
              <span class="mr-2 display-6">Next</span>
              <i class="fas fa-angles-right display-6"></i>
            </button>
            <save
              :saving="verifyBookingRequestLoading"
              v-if="activeStep === 2"
              classes="btn btn-black mb-3 display-6 ml-3"
              type="submit"
            >
              Create Booking
            </save>
          </div>
        </div>
      </div>
    </form>
    <BookingVerificationModal
      :loading="createBookingRequestLoading"
      @verifyToken="createBookingHelper"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import dateFormat from "@/utils/dateFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import BookingVerificationModal from "@/components/booking/BookingVerificationModal.vue";
import Swal from "sweetalert2";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "Booking",
  components: {
    BookingVerificationModal,
  },
  data() {
    return {
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
      activeStep: 1,
      selectedDate: new Date(),
      selectedHour: null,
      selectedTime: null,
      genderOptions: [
        { value: "0", label: "Other" },
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
      ],
      masks: {
        input: "YYYY-MM-DD",
      },
      dataToSubmit: {
        token: null,
        treatment_id: null,
        provider_id: null,
        start_time: null,
        end_time: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        birth_date: null,
        gender: null,
        additional_notes: null,
      },
    };
  },
  mounted() {
    this.getTreatments();
  },
  watch: {
    "dataToSubmit.treatment_id": function (newVal) {
      if (newVal) {
        this.selectedDate = new Date();
        this.selectedHour = null;
        this.selectedTime = null;
        this.getTreatmentAvailabilityHelper(
          newVal,
          this.selectedDate,
          this.selectedDate
        );
      }
    },
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      treatments: (state) => state.booking.treatments.data,
      treatmentsLoading: (state) => state.booking.treatments.loading,
      treatmentAvailability: (state) =>
        state.booking.treatmentAvailability.data,
      treatmentAvailabilityLoading: (state) =>
        state.booking.treatmentAvailability.loading,
      verifyBookingRequestLoading: (state) =>
        state.booking.verifyBookingRequestLoading,
      createBookingRequestLoading: (state) =>
        state.booking.createBookingRequestLoading,
    }),
    showAvailabilityCalendar: function () {
      return this.dataToSubmit.treatment_id;
    },
    nextButtonDisabled: function () {
      return (
        !this.dataToSubmit.treatment_id ||
        !this.selectedDate ||
        !this.selectedTime
      );
    },
    formattedSelectedDate: function () {
      return this.selectedDate
        ? dateFormat(this.selectedDate, "MMMM DD, YYYY")
        : "Please select a date";
    },
    availableHours: function () {
      if (this.treatmentAvailability?.slots) {
        const hoursSet = new Set();
        return this.treatmentAvailability.slots
          .map((date) => {
            const hourLabel = dayjs(date.start).format("h A");
            if (!hoursSet.has(hourLabel)) {
              hoursSet.add(hourLabel);
              return {
                label: hourLabel,
                value: date.start,
              };
            }
            return null;
          })
          .filter((date) => date !== null);
      }
      return [];
    },
    availableTimes() {
      if (this.treatmentAvailability?.slots) {
        return this.treatmentAvailability.slots
          .filter((slot) => {
            return this.selectedHour
              ? dayjs(slot.start).format("h A") ===
                  dayjs(this.selectedHour).format("h A")
              : true;
          })
          .map((slot) => {
            return {
              label: dayjs(slot.start).format("hh:mm A"),
              value: slot.start,
            };
          });
      }
      return [];
    },
    userAvatar: function () {
      return this.loggedInUser?.profile_picture || this.profileImagePlaceholder;
    },
  },
  methods: {
    ...mapActions({
      getTreatments: "booking/getTreatments",
      getTreatmentAvailability: "booking/getTreatmentAvailability",
      verifyBookingRequest: "booking/verifyBookingRequest",
      createBookingRequest: "booking/createBookingRequest",
    }),
    handleStep: function (step) {
      this.activeStep += step;
    },
    setStep: function (value) {
      this.activeStep = value;
    },
    onDayClick: function (day) {
      this.selectedDate = day.date;
      this.selectedHour = null;
      this.selectedTime = null;
      this.getTreatmentAvailabilityHelper(
        this.dataToSubmit.treatment_id,
        day.date,
        day.date
      );
    },
    getTreatmentAvailabilityHelper: function (treatmentId, startDate, endDate) {
      const startDateTime = `${dateFormat(
        startDate,
        "YYYY-MM-DD"
      )}T00:00:00.000000Z`;
      const endDateTime = `${dateFormat(
        endDate,
        "YYYY-MM-DD"
      )}T23:59:59.000000Z`;
      const payloadStart = dayjs(startDateTime).utc().format();
      const payloadEnd = dayjs(endDateTime).utc().format();

      this.getTreatmentAvailability({
        treatmentId,
        start: payloadStart,
        end: payloadEnd,
      }).then(() => {});
    },
    verifyBookingRequestHelper: function () {
      this.verifyBookingRequest({
        first_name: this.loggedInUser
          ? this.loggedInUser.first
          : this.dataToSubmit.first_name,
        email: this.loggedInUser
          ? this.loggedInUser.email
          : this.dataToSubmit.email,
      }).then((response) => {
        if (response) {
          this.$modal.show("booking-verification-modal");
        }
      });
    },
    getBookingTimes: function () {
      const treatment = this.treatments.find(
        (treatment) => treatment.id === this.dataToSubmit.treatment_id
      );
      this.dataToSubmit.start_time = `${dateFormat(
        this.selectedDate,
        "YYYY-MM-DD"
      )}T${dayjs(this.selectedTime).format("HH:mm:ss")}.000000Z`;
      this.dataToSubmit.start_time = dayjs(
        this.dataToSubmit.start_time
      ).format();
      this.dataToSubmit.end_time = `${dateFormat(
        this.selectedDate,
        "YYYY-MM-DD"
      )}T${dayjs(this.selectedTime)
        .add(treatment.duration, "minute")
        .format("HH:mm:ss")}.000000Z`;
      this.dataToSubmit.end_time = dayjs(this.dataToSubmit.end_time).format();

      const canadaOffset = dayjs
        .tz(new Date(this.dataToSubmit.start_time), "America/Toronto")
        .utcOffset();
      const startDateTime = dayjs(this.dataToSubmit.start_time)
        .utc()
        .add(canadaOffset, "minute")
        .format();
      const endDateTime = dayjs(this.dataToSubmit.end_time)
        .utc()
        .add(canadaOffset, "minute")
        .format();

      return { start_time: startDateTime, end_time: endDateTime };
    },
    createBookingHelper: function (token) {
      if (!token) {
        Swal.fire({
          title: "Invalid Token",
          html: `The token you entered is invalid. Please try again.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      if (this.nextButtonDisabled) {
        Swal.fire({
          title: "Invalid Booking",
          html: `Please fill in all the required fields.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      const { start_time, end_time } = this.getBookingTimes();
      this.dataToSubmit = {
        ...this.dataToSubmit,
        email: this.loggedInUser
          ? this.loggedInUser.email
          : this.dataToSubmit.email,
        birth_date: this.dataToSubmit.birth_date
          ? `${dateFormat(
              this.dataToSubmit.birth_date,
              "YYYY-MM-DD"
            )}T00:00:00.000000Z`
          : null,
        is_logged_in: this.loggedInUser ? 1 : 0,
      };
      this.createBookingRequest({
        ...this.dataToSubmit,
        start_time,
        end_time,
        token,
      }).then((response) => {
        if (response) {
          Swal.fire({
            title: "Booking Successful",
            html: `Your booking has been successfully created. You will receive an email confirmation shortly.`,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push({ name: "login" });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-container {
  display: flex;
  flex-flow: column;
  gap: 5px;
  padding: 20px;
  &__header {
    background-color: #f5f5f5;
    display: flex;
    flex-flow: column;
    gap: 5px;
  }
  &__title {
    font-size: clamp(1.3rem, 2vw, 1.5rem);
    font-weight: bold;
  }
  &__subtitle {
    font-size: 16px;
  }
  &__body {
    display: flex;
    flex-flow: column;
    gap: 15px;
  }

  &__user-name {
    font-size: clamp(1.1rem, 2vw, 1.5rem);
    font-weight: bold;
  }

  label {
    font-size: 17px;
  }
  .display-6 {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .treatments-scroll-container {
    max-height: 273px;
    transition: all ease 1000ms;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    .treatments-list {
      display: flex;
      flex-flow: column;
      gap: 10px;
      list-style-type: none;
      padding: 0;

      .treatment-card {
        font-size: clamp(0.8rem, 2vw, 1rem);
        background-color: rgba(37, 37, 37);
        padding: 10px 15px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.1s;
        &:hover {
          background-color: rgba(37, 37, 37, 0.8);
        }
        &.active {
          background-color: var(--theme-color);
          color: var(--main-text-color);
        }
      }
    }
  }

  .booking-date-data {
    padding: 10px 15px;
    background-color: rgba(37, 37, 37);
    border-radius: 4px;
    color: white;
    font-size: clamp(1rem, 2vw, 1.1rem);
    text-align: center;
  }
  .no-time-available {
    padding: 10px 15px;
    background-color: var(--theme-color);
    border-radius: 4px;
    color: black;
    font-size: clamp(1rem, 2vw, 1.1rem);
    text-align: center;
  }
  .time-dropdown {
    padding: 10px 15px;
    font-size: clamp(1rem, 2vw, 1.1rem);
  }
}
</style>
<style lang="scss">
.booking-container {
  .vc-container {
    height: 100% !important;
    .vc-weeks {
      height: 100% !important;
      .is-disabled {
        pointer-events: none;
      }
    }
  }
}
</style>

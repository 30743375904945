<template>
  <sidebar :toggle="openUpdateTray" @close="closeUpdateTray(true)">
    <loader
      v-if="openUpdateTray && actionInProgress"
      class="event-loader"
    ></loader>
    <form
      validate
      ref="form"
      @submit.prevent="UpdateBook()"
      v-if="openUpdateTray && !gettingApt"
    >
      <div class="row">
        <div class="mr-2 mb-3">
          <button
            v-if="
              clickedEventDetails_meetingUrl != null &&
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id)
            "
            class="btn btn-secondary active"
            @click.prevent="joinMeeting()"
          >
            Join Virtual Meeting
          </button>
        </div>
        <div
          class="mr-2"
          v-if="
            user &&
            !user.isPatient &&
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id)
          "
        >
          <button
            class="btn btn-theme mb-3"
            type="submit"
            :disabled="clickedEventDetails_status == 'Verified'"
          >
            Save Changes
          </button>
        </div>

        <div
          v-if="
            (clickedEventDetails_status == 'Ready for Review' ||
              clickedEventDetails_status == 'PENDING') &&
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id)
          "
        >
          <a
            class="btn btn-info mb-2"
            v-if="
              user &&
              (user.isAdmin ||
                user.isManagingAdmin ||
                (currentProvider && currentProvider.can_verify_event))
            "
            @click.prevent="approveEvent"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-if="loading"
            ></span>
            Verify
          </a>
        </div>

        <div
          v-if="
            clickedEventDetails_status == 'Verified' &&
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id)
          "
        >
          <a
            class="btn btn-warning mb-2"
            v-if="user && (user.isAdmin || user.isManagingAdmin)"
            @click.prevent="unapproveEvent"
          >
            Un-Verify
          </a>
        </div>
        <h2
          v-if="
            !clickedEventDetails_deleted &&
            !canEnable(clickedEventDetails_provider_user_id) &&
            clickedEventDetails_type != null
          "
          class="col-12 mt-2 mb-0 pl-2"
          :class="`${
            clickedEventDetails_status != null
              ? clickedEventDetails_status == 'PENDING'
                ? 'text-warning'
                : clickedEventDetails_status == 'Verified'
                ? 'text-success'
                : clickedEventDetails_status
              : 'text-warning'
          }`"
        >
          {{
            clickedEventDetails_status != null
              ? clickedEventDetails_status == "PENDING"
                ? "Pending"
                : clickedEventDetails_status == "Verified"
                ? "Verified"
                : clickedEventDetails_status
              : "Pending"
          }}
        </h2>
        <div
          v-if="
            user &&
            (user.isAdmin ||
              user.isManagingAdmin ||
              (user.isProvider && clickedEventDetails_status != 'Verified'))
          "
        >
          <button
            class="btn btn-danger ml-2 mr-2 mb-3"
            @click.prevent="deleteEvent"
            v-if="
              !clickedEventDetails_deleted &&
              canEnable(clickedEventDetails_provider_user_id)
            "
          >
            Delete
          </button>

          <i
            class="fas fa-xl fa-user-secret ml-4"
            v-if="clickedEventDetails_hidden"
          ></i>

          <h1
            v-if="clickedEventDetails_deleted"
            class="text-danger font-weight-bold mb-0"
            style="letter-spacing: 2px"
          >
            REMOVED
          </h1>
        </div>
      </div>
      <div class="row"></div>
      <div class="row">
        <h3
          class="col-md-12 mt-4"
          v-if="
            clickedEventDetails_patient != null &&
            clickedEventDetails_type != null
          "
        >
          {{ clickedEventDetails_type }} Appointment with
          {{ clickedEventDetails_patient.last }},
          {{ clickedEventDetails_patient.first }}
        </h3>
      </div>
      <div
        class="row"
        v-if="
          !clickedEventDetails_deleted &&
          canEnable(clickedEventDetails_provider_user_id)
        "
      >
        <h2
          class="col-md-6 mt-4"
          :class="`${
            clickedEventDetails_status != null
              ? clickedEventDetails_status == 'PENDING'
                ? 'text-warning'
                : clickedEventDetails_status == 'Verified'
                ? 'text-success'
                : clickedEventDetails_status
              : 'text-warning'
          }`"
          v-if="clickedEventDetails_type != null"
        >
          {{
            clickedEventDetails_status != null
              ? clickedEventDetails_status == "PENDING"
                ? "Pending"
                : clickedEventDetails_status == "Verified"
                ? "Verified"
                : clickedEventDetails_status
              : "Pending"
          }}
        </h2>
        <div
          v-if="user && !user.isPatient"
          class="
            col-md-6
            text-right
            align-items-center
            d-flex
            flex-column
            justify-content-end
          "
        >
          <button
            type="button"
            class="btn btn-lg btn-black"
            @click.prevent="createNote"
            v-if="canHaveClinicalNotes()"
          >
            Add Clinical Note
          </button>
          <a
            href="/"
            v-if="canHaveClinicalNotes()"
            class="h4 mb-0 mt-3 text-underline"
            @click.prevent="navigateToClinicalNotes()"
            ><u>Show Client Notes</u></a
          >
        </div>
      </div>
      <div
        class="row mt-2 mx-0 align-items-center"
        v-if="
          !user.isPatient &&
          !clickedEventDetails_deleted &&
          canEnable(clickedEventDetails_provider_user_id)
        "
      >
        <button
          type="button"
          class="btn btn-sm btn-warning font-weight-bold dropdown-toggle"
          id="calendar-button"
          ref="externalCalendarButton"
          @click.prevent="addToExternalCalendar"
        >
          <i class="far fa-calendar-plus mr-2 mt-2"></i> Add To External
          Calendar
        </button>
        <i
          class="fa fa-info-circle ml-2 pointer-cursor"
          v-tooltip.top="
            `Make sure your calendar account's timezone matches the current device's timezone (${currentTimeZone}) to avoid time difference issues.`
          "
        ></i>
      </div>
      <div
        class="row mt-3"
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
      >
        <div
          class="col-12 row m-0 mb-3 mb-sm-0"
          v-if="
            clickedEventDetails_status != 'Verified' &&
            user &&
            (user.isAdmin || user.isManagingAdmin)
          "
        >
          <label class="mr-5"
            >Is this hidden?
            <i
              class="fa fa-info-circle ml-2 pointer-cursor"
              v-tooltip.top="
                'once the session marked as hidden then only admins and managers can see it'
              "
            ></i>
          </label>
          <div class="custom-control custom-switch ml-4 ml-sm-5 mr-sm-2">
            <input
              type="checkbox"
              class="custom-control-input"
              id="isHidden"
              :disabled="clickedEventDetails_deleted"
              v-model="clickedEventDetails_hidden"
            />
            <label class="custom-control-label pointer-cursor" for="isHidden">{{
              clickedEventDetails_hidden ? "Hidden" : "Visible"
            }}</label>
          </div>
        </div>
      </div>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            class="nav-item nav-link active"
            id="nav-home-tab"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            >Details</a
          >
          <a
            class="nav-item nav-link"
            id="nav-memo-tab"
            data-toggle="tab"
            href="#nav-memo"
            role="tab"
            aria-controls="nav-memo"
            aria-selected="false"
            >Memo & Reason</a
          >
          <a
            v-if="
              user &&
              !user.isPatient &&
              clickedEventDetails_service.id &&
              isBillable
            "
            class="nav-item nav-link"
            id="nav-profile-tab"
            data-toggle="tab"
            href="#nav-profile"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            >Sub Services</a
          >
          <a
            v-if="user && !user.isPatient && isBillable"
            class="nav-item nav-link"
            id="nav-expenses-tab"
            data-toggle="tab"
            href="#nav-expenses"
            role="tab"
            aria-controls="nav-expenses"
            aria-selected="false"
            >Expenses</a
          >
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div class="row">
            <label class="col-12">Appointment Time</label>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <datetime
                  v-model="clickedEventDetails_startStr"
                  @input="setChangesMade"
                  :disabled="
                    clickedEventDetails_status == 'Verified' ||
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id)
                  "
                  placeholder="DateTime"
                  :week-start="7"
                  input-class="col-md-12 form-control"
                  input-style="font-size: 12px;"
                  type="datetime"
                >
                </datetime>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <datetime
                  v-model="clickedEventDetails_endStr"
                  @input="setChangesMade"
                  :disabled="
                    clickedEventDetails_status == 'Verified' ||
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id)
                  "
                  placeholder="DateTime"
                  :week-start="7"
                  input-class="col-md-12 form-control"
                  input-style="font-size: 12px;"
                  type="datetime"
                >
                </datetime>
                <div
                  class="text-right pr-2"
                  :style="`opacity: ${
                    clickedEventDetails_mainService &&
                    clickedEventDetails_mainService.unit !== 'FT' &&
                    clickedEventDetails_mainService.unit !== 'FLAT'
                      ? '1'
                      : '0'
                  }`"
                >
                  <span
                    v-if="
                      clickedEventDetails_status != 'Verified' &&
                      !clickedEventDetails_deleted &&
                      canEnable(clickedEventDetails_provider_user_id)
                    "
                    class="h4 text-primary pointer-cursor"
                    @click.prevent="setTimeToZero()"
                    >Set main service to zero hrs</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div
                class="form-group"
                v-if="clickedEventDetails_provider != null"
              >
                <label>Provider/Admin</label>
                <multiselect
                  v-model="select.provider"
                  class="app-select no-labels"
                  :options="user1Options"
                  :preserve-search="true"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  placeholder="Search for Provider/Admin"
                  label="name"
                  :disabled="
                    (user && (user.isPatient || user.isProvider)) ||
                    clickedEventDetails_status == 'Verified' ||
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id)
                  "
                  @input="updateSelectedUser1($event.id)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex flex-column">
                        <div class="font-weight-bold select-label">
                          {{ props.option.name }}
                        </div>
                        <div class="small email-container" style="opacity: 0.9">
                          ({{ props.option.email }})
                        </div>
                      </div>
                      <div class="d-flex">
                        <div
                          class="small font-weight-bold select-sublabel"
                          style="opacity: 0.9"
                        >
                          ({{ props.option.type }})
                        </div>
                        <div
                          class="text-danger small ml-3"
                          v-if="props.option.$isDisabled"
                        >
                          Inactive
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #noResult>
                    <div class="no-result">Oops! No users found.</div>
                  </template>
                </multiselect>
                <div
                  class="
                    px-1
                    mb-2
                    pt-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <a
                    v-if="
                      user &&
                      (user.isAdmin || user.isManagingAdmin) &&
                      !isBillable &&
                      clickedEventDetails_status != 'Verified' &&
                      !clickedEventDetails_deleted &&
                      canEnable(clickedEventDetails_provider_user_id)
                    "
                    href="#"
                    class="h4 mb-0"
                    @click.prevent="updateSelectedUser1(user.id)"
                    >Assign to me</a
                  >
                  <a
                    href="#"
                    class="h4 mb-0 ml-auto"
                    v-if="clickedEventDetails_provider_user_id"
                    @click.prevent="
                      openUserAvailabilityModal(
                        clickedEventDetails_provider_user_id
                      )
                    "
                    >Availability</a
                  >
                </div>
              </div>
              <div
                class="form-group"
                v-if="
                  clickedEventDetails_patient != null &&
                  user &&
                  (user.isProvider || user.isAdmin || user.isManagingAdmin)
                "
              >
                <label>{{ isBillable ? "Client" : "User/Client" }}</label>
                <multiselect
                  v-model="select.patient"
                  class="app-select no-labels"
                  :options="user2Options"
                  :preserve-search="true"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  :placeholder="`Search for ${
                    isBillable ? 'Client' : 'User/Client'
                  }`"
                  label="name"
                  :disabled="
                    clickedEventDetails_status == 'Verified' ||
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id)
                  "
                  @input="updateSelectedUser2($event.id)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex flex-column">
                        <div class="font-weight-bold select-label">
                          {{ props.option.name }}
                        </div>
                        <div class="small email-container" style="opacity: 0.9">
                          ({{ props.option.email }})
                        </div>
                      </div>
                      <div class="d-flex">
                        <div
                          class="small font-weight-bold select-sublabel"
                          style="opacity: 0.9"
                        >
                          ({{ props.option.type }})
                        </div>
                        <div
                          class="text-danger small ml-3"
                          v-if="props.option.$isDisabled"
                        >
                          Inactive
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #noResult>
                    <div class="no-result">Oops! No users found.</div>
                  </template>
                </multiselect>
                <div
                  class="
                    px-1
                    mb-2
                    pt-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <a
                    v-if="
                      !isBillable &&
                      clickedEventDetails_status != 'Verified' &&
                      !clickedEventDetails_deleted &&
                      canEnable(clickedEventDetails_provider_user_id)
                    "
                    href="#"
                    class="h4 ml-2"
                    @click.prevent="
                      updateSelectedUser2(user.id), setChangesMade()
                    "
                    >Assign to me</a
                  >
                  <a
                    href="#"
                    class="h4 mb-0 ml-auto"
                    v-if="clickedEventDetails_patient_user_id"
                    @click.prevent="
                      openUserAvailabilityModal(
                        clickedEventDetails_patient_user_id
                      )
                    "
                    >Availability</a
                  >
                </div>
              </div>
              <div class="form-group">
                <label>Service</label>
                <multiselect
                  v-model="select.primary"
                  class="app-select no-labels"
                  :options="serviceOptions"
                  :preserve-search="true"
                  :searchable="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  placeholder="Search for Service"
                  label="name"
                  :disabled="
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id) ||
                    !(
                      (clickedEventDetails_status == 'Ready for Review' ||
                        clickedEventDetails_status == 'PENDING') &&
                      user &&
                      (user.isAdmin ||
                        user.isManagingAdmin ||
                        (currentProvider && currentProvider.can_verify_event))
                    )
                  "
                  @input="updateSelectedService($event.id)"
                >
                  <template slot="option" slot-scope="props">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="font-weight-bold select-label">
                        {{ props.option.name }}
                      </div>
                      <div
                        class="small font-weight-bold select-sublabel"
                        v-if="props.option.type"
                        style="opacity: 0.9"
                      >
                        ({{ props.option.type }})
                      </div>
                    </div>
                  </template>
                  <template #noResult>
                    <div class="no-result">Oops! No services found.</div>
                  </template>
                </multiselect>
                <div
                  class="w-100 text-right"
                  v-if="
                    user &&
                    !user.isPatient &&
                    clickedEventDetails_service.id &&
                    isBillable &&
                    !clickedEventDetails_deleted &&
                    canEnable(clickedEventDetails_provider_user_id)
                  "
                >
                  <a
                    href="#nav-profile"
                    @click.prevent="selectTab('#nav-profile-tab')"
                    class="h4 mr-1"
                    >Add sub service</a
                  >
                </div>
              </div>
              <input-control
                v-if="selectedEvent.treatment"
                type="vertical"
                v-model="selectedEvent.treatment.name"
                control="text"
                disabled
              >
                Treatment
                <small class="text-muted"> - View only </small>
              </input-control>
              <input-control
                control="number"
                :disabled="
                  clickedEventDetails_status == 'Verified' ||
                  clickedEventDetails_deleted
                "
                v-model="clickedEventDetails_providerRate"
                @input="setChangesMade"
                v-if="
                  canEnable(clickedEventDetails_provider_user_id) &&
                  user &&
                  !user.isPatient
                "
                type="vertical"
                step="0.1"
                min="0"
                >Provider Rate</input-control
              >
              <div
                class="form-group"
                v-if="
                  clickedEventDetails_provider != null &&
                  user &&
                  !user.isProvider
                "
              >
                <label>Appointment Type</label>
                <select
                  :disabled="
                    (user && user.isPatient) ||
                    clickedEventDetails_status == 'Verified' ||
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id)
                  "
                  required
                  v-model="clickedEventDetails_type"
                  @change="setChangesMade"
                  class="form-control"
                >
                  <option
                    v-for="p in serviceTypes"
                    :key="p.value"
                    :value="p.value"
                  >
                    {{ p.label }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="col-12 mb-2"
              v-if="
                clickedEventDetails_status !== 'Verified' &&
                !clickedEventDetails_deleted &&
                canEnable(clickedEventDetails_provider_user_id)
              "
            >
              <input
                type="checkbox"
                id="allowOverlap"
                v-model="allowOverlap"
                @change="setChangesMade"
              />
              <label
                for="allowOverlap"
                class="pointer-cursor ml-2 text-dark-red"
                >Allow Overlapping With Other Sessions</label
              >
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <label class="serviceName"
            >{{
              clickedEventDetails_mainService != null
                ? clickedEventDetails_mainService.name
                : ""
            }}
          </label>
          <div
            v-if="
              clickedEventDetails_services != null &&
              clickedEventDetails_services.length != 0
            "
          >
            <span style="color: #f00; font-size: 12px"
              >* Only the selected Sub Service will be submitted</span
            >

            <div
              class="subServiceItem col-md-12"
              v-for="subService in clickedEventDetails_services"
              :key="subService.id"
            >
              <div class="row mb-2 align-items-center">
                <input
                  class="form-control pointer-cursor checkbox-input"
                  type="checkbox"
                  :id="`${subService.name}_${subService.id}`"
                  v-model="subService.selected"
                  @change="setChangesMade"
                  :disabled="
                    clickedEventDetails_deleted ||
                    !canEnable(clickedEventDetails_provider_user_id) ||
                    subService.is_default == true ||
                    clickedEventDetails_status == 'Verified' ||
                    (subService.status == 'INACTIVE' && !subService.selected)
                  "
                />
                <label
                  :for="`${subService.name}_${subService.id}`"
                  class="pointer-cursor ml-2 mb-0"
                  >Selected <span style="color: #f00">*</span></label
                >
                <span
                  v-if="subService.status == 'INACTIVE' && !subService.selected"
                  class="font-weight-bold text-danger float-right"
                  v-tooltip.top="
                    'The sub service has no longer been active and can\'t be used anymore'
                  "
                  >INACTIVE</span
                >
              </div>
              <div
                class="subServiceItemContainer"
                :class="
                  subService.something == false ? 'disabledSubService1' : ''
                "
              >
                <h3 class="SubServiceTitle">{{ subService.name }}</h3>
                <div class="row">
                  <div
                    class="col-md-4"
                    v-if="subService.pivot && user && !user.isProvider"
                  >
                    <input-control
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        (user && user.isProvider) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected) ||
                        true
                      "
                      type="vertical"
                      v-model="subService.pivot.rate"
                      @change="setChangesMade"
                      @input="setChangesMade"
                      control="number"
                      step="0.01"
                      >Rate</input-control
                    >
                  </div>

                  <div
                    class="col-md-4"
                    v-if="!subService.pivot && user && !user.isProvider"
                  >
                    <input-control
                      type="vertical"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        (user && user.isProvider) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected) ||
                        true
                      "
                      v-model="subService.rate"
                      @change="setChangesMade"
                      @input="setChangesMade"
                      control="number"
                      step="0.01"
                      >Rate</input-control
                    >
                  </div>

                  <div
                    class="mt-3 mt-md-0"
                    :class="`${
                      user && user.isProvider ? 'col-md-6' : 'col-md-4'
                    }`"
                    v-if="subService.pivot"
                  >
                    <input-control
                      type="vertical"
                      v-model="subService.pivot.quantity"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected) ||
                        subService.unit == 'FT' ||
                        subService.unit == 'FLAT'
                      "
                      @change="setChangesMade"
                      @input="setChangesMade"
                      :required="subService.selected"
                      control="number"
                      step="0.01"
                      >Quantity</input-control
                    >
                  </div>

                  <div
                    class="mt-3 mt-md-0"
                    :class="`${
                      user && user.isProvider ? 'col-md-6' : 'col-md-4'
                    }`"
                    v-else
                  >
                    <input-control
                      type="vertical"
                      v-model="subService.qty"
                      @change="setChangesMade"
                      @input="setChangesMade"
                      :required="subService.selected"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected) ||
                        subService.unit == 'FT' ||
                        subService.unit == 'FLAT'
                      "
                      control="number"
                      step="0.01"
                      >Quantity</input-control
                    >
                  </div>

                  <div
                    class="mt-3 mt-md-0"
                    :class="`${
                      user && user.isProvider ? 'col-md-6' : 'col-md-4'
                    }`"
                    v-if="subService.pivot"
                  >
                    <select-control
                      type="vertical"
                      v-model="subService.pivot.unit"
                      @change="setChangesMade"
                      @input="setChangesMade"
                      labelClass="mr-0"
                      disabled
                      :options="units"
                      >Unit</select-control
                    >
                  </div>

                  <div
                    class="mt-3 mt-md-0"
                    :class="`${
                      user && user.isProvider ? 'col-md-6' : 'col-md-4'
                    }`"
                    v-else
                  >
                    <select-control
                      type="vertical"
                      v-model="subService.unit"
                      disabled
                      @change="setChangesMade"
                      @input="setChangesMade"
                      labelClass="mr-0"
                      :options="units"
                      >Unit</select-control
                    >
                  </div>
                </div>

                <div class="row mt-3" v-if="subService.pivot">
                  <div class="col-md-6" v-if="false">
                    <div class="row">
                      <label class="col-md-12">Taxable</label>
                    </div>
                    <input
                      type="radio"
                      id="yes"
                      value="1"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected)
                      "
                      v-model="subService.pivot.taxable"
                      @change="setChangesMade"
                      @input="setChangesMade"
                    />
                    <label for="yes">Yes</label>
                    <input
                      type="radio"
                      id="no"
                      value="0"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected)
                      "
                      v-model="subService.pivot.taxable"
                      @change="setChangesMade"
                      @input="setChangesMade"
                    />
                    <label for="no">No</label>
                  </div>
                </div>

                <div class="row mt-3" v-else>
                  <div class="col-md-6" v-if="false">
                    <div class="row">
                      <label class="col-md-12">Taxable</label>
                    </div>
                    <input
                      type="radio"
                      id="yes"
                      value="true"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected)
                      "
                      v-model="subService.taxable"
                      @change="setChangesMade"
                      @input="setChangesMade"
                    />
                    <label for="yes">Yes</label>
                    <input
                      type="radio"
                      id="no"
                      value="false"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected)
                      "
                      v-model="subService.taxable"
                      @change="setChangesMade"
                      @input="setChangesMade"
                    />
                    <label for="no">No</label>
                  </div>
                </div>

                <div class="row mt-3" v-if="subService.pivot">
                  <div class="col-md-12">
                    <input-control
                      type="vertical"
                      rows="6"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected)
                      "
                      v-model="subService.pivot.description"
                      @change="setChangesMade"
                      @input="setChangesMade"
                      maxLength="500"
                      control="textarea"
                      >Description
                    </input-control>
                  </div>
                </div>

                <div class="row mt-3" v-else>
                  <div class="col-md-12">
                    <input-control
                      type="vertical"
                      rows="6"
                      v-model="subService.description"
                      :disabled="
                        clickedEventDetails_deleted ||
                        !canEnable(clickedEventDetails_provider_user_id) ||
                        clickedEventDetails_status == 'Verified' ||
                        (subService.status == 'INACTIVE' &&
                          !subService.selected)
                      "
                      @change="setChangesMade"
                      @input="setChangesMade"
                      maxLength="500"
                      control="textarea"
                      >Description
                    </input-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 text-center mb-5">
            <a
              class="btn btn-black"
              href="#nav-home"
              @click.prevent="selectTab('#nav-home-tab')"
              >Back To Details</a
            >
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-memo"
          role="tabpanel"
          aria-labelledby="nav-memo-tab"
        >
          <div class="form-group" v-if="user && !user.isPatient">
            <label
              >Memo
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="'Seen in billing & payroll reports'"
              ></i
            ></label>
            <textarea
              class="form-control"
              :disabled="
                clickedEventDetails_status == 'Verified' ||
                clickedEventDetails_deleted ||
                !canEnable(clickedEventDetails_provider_user_id)
              "
              v-model="clickedEventDetails_memo"
              @change="setChangesMade"
              rows="5"
            ></textarea>
          </div>
          <div class="form-group">
            <label
              >Reason{{
                user && !user.isPatient ? " - seen by client" : ""
              }}</label
            >
            <textarea
              :disabled="
                (user && user.isPatient) ||
                clickedEventDetails_deleted ||
                clickedEventDetails_status == 'Verified' ||
                !canEnable(clickedEventDetails_provider_user_id)
              "
              class="form-control"
              v-model="clickedEventDetails_reason"
              @change="setChangesMade"
              rows="5"
            ></textarea>
          </div>
          <div class="w-100 text-center mb-5">
            <a
              class="btn btn-black"
              href="#nav-home"
              @click.prevent="selectTab('#nav-home-tab')"
              >Back To Details</a
            >
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-expenses"
          role="tabpanel"
          aria-labelledby="nav-expenses-tab"
        >
          <div class="form-group">
            <input-control
              control="number"
              :disabled="
                clickedEventDetails_status == 'Verified' ||
                clickedEventDetails_deleted ||
                !canEnable(clickedEventDetails_provider_user_id)
              "
              v-model="clickedEventDetails_expenses"
              @input="setChangesMade"
              type="vertical"
              step="0.01"
              min="0"
              >Expenses $</input-control
            >
            <input-control
              type="vertical"
              rows="6"
              v-model="clickedEventDetails_billing_details"
              @change="setChangesMade"
              :disabled="
                clickedEventDetails_status == 'Verified' ||
                clickedEventDetails_deleted ||
                !canEnable(clickedEventDetails_provider_user_id)
              "
              @input="setChangesMade"
              control="textarea"
              >Billing Details
            </input-control>
          </div>
          <div class="w-100 text-center mb-5">
            <a
              class="btn btn-black"
              href="#nav-home"
              @click.prevent="selectTab('#nav-home-tab')"
              >Back To Details</a
            >
          </div>
        </div>
      </div>
      <div class="col p-0 mb-4" v-if="user && !user.isPatient">
        <div
          v-if="
            clickedEventDetails_createdBy &&
            clickedEventDetails_createdAt &&
            clickedEventDetails_createdBy != '-' &&
            clickedEventDetails_createdAt != '-'
          "
          class="mb-2"
        >
          Created by
          <b class="text-danger">{{ clickedEventDetails_createdBy }}</b> at
          <span class="text-secondary">{{
            clickedEventDetails_createdAt
          }}</span>
        </div>
        <div
          v-if="
            clickedEventDetails_verifiedBy &&
            clickedEventDetails_verifiedAt &&
            clickedEventDetails_verifiedBy != '-' &&
            clickedEventDetails_verifiedAt != '-'
          "
        >
          {{
            clickedEventDetails_status == "Verified" ? "Verified" : "Unverified"
          }}
          by
          <b class="text-danger">{{ clickedEventDetails_verifiedBy }}</b> at
          <span class="text-secondary">{{
            clickedEventDetails_verifiedAt
          }}</span>
        </div>
        <div v-if="clickedEventDetails_deleted" class="mt-2">
          Removed by
          <b class="text-danger">{{ clickedEventDetails_deletedBy }}</b> at
          <span class="text-secondary">{{
            clickedEventDetails_deletedAt
          }}</span>
        </div>
      </div>
      <div class="row m-0 d-flex justify-content-center">
        <div
          v-if="
            user &&
            !user.isPatient &&
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id)
          "
        >
          <button
            class="btn btn-theme"
            type="submit"
            v-if="clickedEventDetails_status != 'Verified'"
          >
            Save Changes
          </button>
        </div>
        <div
          v-if="
            !clickedEventDetails_deleted &&
            canEnable(clickedEventDetails_provider_user_id) &&
            (clickedEventDetails_status == 'Ready for Review' ||
              clickedEventDetails_status == 'PENDING')
          "
        >
          <a
            class="btn btn-info mb-2 ml-3"
            v-if="
              user &&
              (user.isAdmin ||
                user.isManagingAdmin ||
                (currentProvider && currentProvider.can_verify_event))
            "
            @click.prevent="approveEvent"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-if="loading"
            ></span>
            Verify
          </a>
        </div>
      </div>
    </form>
    <div v-if="gettingApt" class="pt-5">
      <alert class="m-4" />
    </div>
  </sidebar>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Datetime } from "vue-datetime";
import Multiselect from "vue-multiselect";
import { atcb_init, atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "edit-calendar-session",
  components: {
    datetime: Datetime,
    Multiselect,
  },
  props: {
    users: Array,
    patients: Array,
    providers: Array,
    nonBillableServices: Array,
    currentProvider: Object,
    parentServicesTemp: Array,
    services: Array,
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      gettingApt: false,
      openUpdateTray: false,
      actionInProgress: false,
      eventModified: false,
      changesMade: false,
      selectedEvent: null,
      allowOverlap: false,
      isBillable: false,
      parentServices: [],
      displayedPatients: [],
      clickedEventDetails_type: null,
      clickedEventDetails_providerRate: 0,
      clickedEventDetails_id: "",
      clickedEventDetails_startStr: "",
      clickedEventDetails_endStr: "",
      clickedEventDetails_title: "",
      clickedEventDetails_start: null,
      clickedEventDetails_end: null,
      clickedEventDetails_primary: null,
      clickedEventDetails_service: null,
      clickedEventDetails_deleted: false,
      clickedEventDetails_end_time: "",
      clickedEventDetails_start_time: "",
      clickedEventDetails_status: "",
      clickedEventDetails_meetingUrl: "",
      clickedEventDetails_memo: "",
      clickedEventDetails_reason: "",
      clickedEventDetails_expenses: 0,
      clickedEventDetails_billing_details: "",
      clickedEventDetails_patient: null,
      clickedEventDetails_provider: null,
      clickedEventDetails_mainService: null,
      clickedEventDetails_provider_user_id: 0,
      clickedEventDetails_patient_user_id: 0,
      clickedEventDetails_hidden: null,
      clickedEventDetails_services: [],
      select: {
        provider: null,
        patient: null,
        primary: null,
      },
      serviceTypes: [
        { value: "VIRTUAL", label: "Virtual" },
        { value: "IN-PERSON", label: "In-Person" },
      ],
      units: [
        { value: "HR", label: "Hourly" },
        { value: "KM", label: "Kilometers" },
        { value: "FLAT", label: "Flat Rate" },
        { value: "FT", label: "Flat Rate" },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      atcb_init();
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    currentTimeZone() {
      return dayjs.tz.guess();
    },
    displayedProviders() {
      if (this.isBillable) {
        return this.sortAlphabetically(this.providers);
      }
      return this.sortAlphabetically(
        this.users.filter((user) => !user.isPatient)
      );
    },
    user1Options() {
      const displayedOptions = [];

      if (this.options.adminUsers.length) {
        const adminUsers = this.options.adminUsers.map((p) => ({
          id: p.id,
          name: `${p.last}, ${p.first}`,
          $isDisabled: p.status === "INACTIVE",
          type: "Admin",
          email: p.email,
        }));
        displayedOptions.push(...adminUsers);
      }

      if (this.options.providerUsers.length) {
        const providerUsers = this.options.providerUsers.map((p) => ({
          id: p.id,
          name: `${p.last}, ${p.first}`,
          disabled: p.status === "INACTIVE",
          type: "Provider",
          email: p.email,
        }));
        displayedOptions.push(...providerUsers);
      }
      return displayedOptions;
    },
    user2Options() {
      const displayedOptions = [];

      if (this.isBillable) {
        const patientUsers = this.displayedPatients.map((p) => ({
          id: p.user_id || p.id,
          name: p.name ? p.name : `${p.last}, ${p.first}`,
          disabled: p.status === "INACTIVE",
          type: "Client",
          email: p.email,
        }));
        displayedOptions.push(...patientUsers);
      } else {
        if (this.user && (this.user.isAdmin || this.user.isManagingAdmin)) {
          const adminUsers = this.options.adminUsers.map((p) => ({
            id: p.id,
            name: `${p.last}, ${p.first}`,
            $isDisabled: p.status === "INACTIVE",
            type: "Admin",
            email: p.email,
          }));
          displayedOptions.push(...adminUsers);
        }

        if (this.options.providerUsers.length) {
          const providerUsers = this.options.providerUsers.map((p) => ({
            id: p.id,
            name: `${p.last}, ${p.first}`,
            disabled: p.status === "INACTIVE",
            type: "Provider",
            email: p.email,
          }));
          displayedOptions.push(...providerUsers);
        }
        if (this.options.patientUsers.length) {
          const patientUsers = this.options.patientUsers.map((p) => ({
            id: p.user_id || p.id,
            name: `${p.last}, ${p.first}`,
            disabled: p.status === "INACTIVE",
            type: "Client",
            email: p.email,
          }));
          displayedOptions.push(...patientUsers);
        }
      }
      return displayedOptions;
    },
    serviceOptions() {
      const displayedOptions = this.parentServices.map((p) => ({
        id: p.id,
        name: p.name,
        type: p.catastrophic ? "CAT" : "",
      }));
      return displayedOptions;
    },
  },
  methods: {
    ...mapActions({
      getClinicalNotesAptIds: "templates/getBasicClinicalNotesAptIds",
    }),
    updateDisplayedPatients: function () {
      const selectedProvider = this.providers.filter(
        (item) => item.user_id == this.clickedEventDetails_provider_user_id
      )[0];
      if (selectedProvider) {
        if (this.clickedEventDetails_patient_user_id) {
          const providerPatients = selectedProvider.patients || [];
          const patients = this.sortAlphabetically(
            this.patients.filter((patient) =>
              providerPatients.includes(patient.id)
            )
          );
          this.displayedPatients = patients;
          if (
            this.isBillable &&
            !this.displayedPatients.filter(
              (item) => item.user_id == this.clickedEventDetails_patient_user_id
            )[0]
          ) {
            this.clickedEventDetails_patient_user_id = null;
            this.select.patient = null;
          }
          return;
        } else {
          this.displayedPatients = this.sortAlphabetically(
            this.patients.filter((patient) =>
              (selectedProvider.patients || []).includes(patient.id)
            )
          );
          return;
        }
      }
      this.displayedPatients = this.sortAlphabetically(this.patients);
    },
    updateSelectedUser1(id) {
      this.select.provider = this.user1Options.filter(
        (user) => user.id == id
      )[0];
      this.clickedEventDetails_provider_user_id = id;
      this.updateDisplayedPatients();
      this.showServicesBasedOnSelectedProvider(id);
    },
    updateSelectedUser2(id) {
      this.select.patient = this.user2Options.filter(
        (user) => user.id == id
      )[0];
      this.clickedEventDetails_patient_user_id = id;
      this.showServicesBasedOnSelectedProvider(id);
    },
    sortAlphabetically(arrayInput) {
      const arraySrc = new Array(...arrayInput);
      const result = arraySrc.sort((a, b) => {
        if (`${a.last} ${a.first}` < `${b.last} ${b.first}`) {
          return -1;
        }
        if (`${a.last} ${a.first}` > `${b.last} ${b.first}`) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    canEnable: function (userId) {
      if ((this.user || {}).isProvider) {
        if (this.user.id !== userId) {
          return false;
        }
      }
      return true;
    },
    setChangesMade() {
      this.changesMade = true;
    },
    updateSelectedService(id) {
      this.clickedEventDetails_service.id = id;
      this.select.primary = this.serviceOptions.filter(
        (service) => service.id == id
      )[0];
      this.clickedEventDetails_mainService = this.parentServices.filter(
        (service) => {
          return service.id == this.clickedEventDetails_service.id;
        }
      )[0];
      this.getSubServicesByParentServiceId(id);
      this.eventModified = true;
    },
    updateProviderRate: function (providerId_Type, serviceId) {
      let isAdmin = false;
      let providerId = providerId_Type;
      if (providerId_Type != null) {
        const providerSplitValue = `${providerId_Type}`.split("_");

        if (providerSplitValue != null) {
          providerId = `${providerId_Type}`.split("_")[0];
          if (`${providerId_Type}`.split("_")[1] == "true") {
            isAdmin = true;
          }
        }
      }

      if (providerId && serviceId) {
        const selectedService = this.services.filter(
          (service) => service.id == serviceId
        )[0];

        let selectedProvider = this.users.filter(
          (user) => user.id == providerId
        )[0];

        if (!isAdmin && !((selectedProvider || {}).professions || []).length) {
          selectedProvider = this.providers.filter(
            (item) => item.user_id == providerId
          )[0];
        }
        if (!selectedProvider) {
          return;
        }
        const providerProfession = (selectedProvider.professions || []).filter(
          (profession) => profession.id == selectedService.profession_id
        )[0];
        if (providerProfession) {
          if (this.openUpdateTray) {
            this.clickedEventDetails_providerRate = providerProfession.rate;
          } else {
            this.booking.provider_rate = providerProfession.rate;
          }
        }
      }
    },
    getSubServicesByParentServiceId: function (parentServiceID) {
      this.setChangesMade();
      this.updateProviderRate(
        this.clickedEventDetails_provider_user_id,
        parentServiceID
      );
      var eventSubServices = this.services.filter(function (item) {
        if (item.is_default) {
          item.selected = true;
          item.qty = 1;
          return item;
        }
        if (item.unit == "FT" || item.unit == "FLAT") {
          item.qty = 1;
        }

        if (item.parent_service_id == parentServiceID) {
          item.selected = false;
          return item;
        }
      });

      this.clickedEventDetails_services = JSON.parse(
        JSON.stringify(eventSubServices)
      );
    },
    openUserAvailabilityModal: function (userId) {
      this.$emit("openUserAvailability", userId);
    },
    selectTab(id) {
      window.$(id).trigger("click");
    },
    getPersonalCalendarDate: function (value, type) {
      if (type == "date") {
        return dayjs(value).format("YYYY-MM-DD");
      } else {
        return dayjs(value).format("HH:mm");
      }
    },
    closeUpdateTray: function (noRefetch = false) {
      if (this.eventModified) {
        this.eventModified = false;
        if (!noRefetch) {
          this.$emit("refetchCalendar");
        }
      }
      this.openUpdateTray = false;
      this.clearEventDetailsData();
    },
    clearEventDetailsData: function () {
      (this.loading = false), (this.gettingApt = true);
      this.actionInProgress = false;
      this.eventModified = false;
      this.changesMade = false;
      this.selectedEvent = null;
      this.allowOverlap = false;
      this.isBillable = false;
      this.parentServices = [];
      this.displayedPatients = [];
      this.clickedEventDetails_type = null;
      this.clickedEventDetails_providerRate = 0;
      this.clickedEventDetails_id = "";
      this.clickedEventDetails_startStr = "";
      this.clickedEventDetails_endStr = "";
      this.clickedEventDetails_title = "";
      this.clickedEventDetails_start = null;
      this.clickedEventDetails_end = null;
      this.clickedEventDetails_primary = null;
      this.clickedEventDetails_service = null;
      this.clickedEventDetails_deleted = false;
      this.clickedEventDetails_end_time = "";
      this.clickedEventDetails_start_time = "";
      this.clickedEventDetails_status = "";
      this.clickedEventDetails_meetingUrl = "";
      this.clickedEventDetails_memo = "";
      this.clickedEventDetails_reason = "";
      this.clickedEventDetails_expenses = 0;
      this.clickedEventDetails_billing_details = "";
      this.clickedEventDetails_patient = null;
      this.clickedEventDetails_provider = null;
      this.clickedEventDetails_mainService = null;
      this.clickedEventDetails_provider_user_id = 0;
      this.select.provider = null;
      this.clickedEventDetails_patient_user_id = 0;
      this.select.patient = null;
      this.clickedEventDetails_hidden = null;
      this.clickedEventDetails_services = [];
    },
    getProvider: function (item) {
      let tempItem;
      if (item.provider) {
        tempItem = item.provider;
      } else {
        tempItem = item.user_1 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    initEvent: function (clickInfo) {
      this.gettingApt = true;
      this.clearEventDetailsData();
      this.openUpdateTray = true;
      this.$http
        .get(`users/appointments/${clickInfo.event.id}`)
        .then((res) => {
          this.selectedEvent = ((res || {}).data || {}).data;

          this.clickedEventDetails_startStr = dayjs(
            this.selectedEvent.start_time
          )
            .add(
              -1 * dayjs(this.selectedEvent.start_time).utcOffset(),
              "minute"
            )
            .format();

          this.clickedEventDetails_endStr = dayjs(this.selectedEvent.end_time)
            .add(-1 * dayjs(this.selectedEvent.end_time).utcOffset(), "minute")
            .format();

          this.isBillable = this.selectedEvent.billable == "1";
          this.clickedEventDetails_hidden = this.selectedEvent.hidden;
          this.clickedEventDetails_id = this.selectedEvent.id;
          this.clickedEventDetails_title = clickInfo.event.title;
          this.clickedEventDetails_start = clickInfo.event.start;
          this.clickedEventDetails_end = clickInfo.event.end;
          this.clickedEventDetails_primary =
            this.selectedEvent.services[0] || {};
          const user1 = this.getProvider(this.selectedEvent);
          this.clickedEventDetails_organizer = `${user1.last}, ${user1.first}`;
          this.clickedEventDetails_deleted = this.selectedEvent.deleted;
          this.clickedEventDetails_service =
            this.selectedEvent.services[0] || {};
          this.clickedEventDetails_end_time = this.clickedEventDetails_endStr;
          this.clickedEventDetails_start_time =
            this.clickedEventDetails_startStr;
          this.clickedEventDetails_status = this.selectedEvent.status;
          this.clickedEventDetails_type = this.selectedEvent.type;
          this.clickedEventDetails_memo = this.selectedEvent.memo;
          this.clickedEventDetails_reason = this.selectedEvent.reason;
          this.clickedEventDetails_createdBy = this.selectedEvent.created_by;
          this.clickedEventDetails_createdAt =
            this.selectedEvent.created_at &&
            this.selectedEvent.created_at != "-"
              ? dayjs(this.selectedEvent.created_at).format(
                  "MMM DD, YYYY  hh:mm A"
                )
              : "-";
          this.clickedEventDetails_deletedBy = this.selectedEvent.deleted_by;
          this.clickedEventDetails_deletedAt =
            this.selectedEvent.deleted_at &&
            this.selectedEvent.deleted_at != "-"
              ? dayjs(this.selectedEvent.deleted_at).format(
                  "MMM DD, YYYY  hh:mm A"
                )
              : "-";
          this.clickedEventDetails_verifiedBy = this.selectedEvent.verified_by;
          this.clickedEventDetails_verifiedAt =
            this.selectedEvent.verified_at &&
            this.selectedEvent.verified_at != "-"
              ? dayjs(this.selectedEvent.verified_at)
                  .add(
                    dayjs(this.selectedEvent.verified_at).utcOffset(),
                    "minute"
                  )
                  .format("MMM DD, YYYY  hh:mm A")
              : "-";
          this.clickedEventDetails_expenses = this.selectedEvent.expenses;
          this.clickedEventDetails_billing_details =
            this.selectedEvent.billing_details;
          this.clickedEventDetails_providerRate =
            this.selectedEvent.provider_rate;

          // If admin / provider can start the meeting as a host, if patient, will joing the meeting as a guest
          if (
            (this.user && this.user.isAdmin) ||
            this.user.isManagingAdmin ||
            this.user.isProvider
          ) {
            this.clickedEventDetails_meetingUrl = this.selectedEvent.start_url;
          } else {
            this.clickedEventDetails_meetingUrl = this.selectedEvent.join_url;
          }

          this.clickedEventDetails_patient =
            this.selectedEvent.patient || this.selectedEvent.user_2;
          this.clickedEventDetails_patient_user_id =
            this.clickedEventDetails_patient.user_id ||
            this.clickedEventDetails_patient.id;
          this.select.patient = this.user2Options.filter(
            (user) => user.id == this.clickedEventDetails_patient_user_id
          )[0];
          if (!this.select.patient) {
            this.select.patient = [this.clickedEventDetails_patient].map(
              (p) => ({
                id: p.user_id || p.id,
                name: p.name ? p.name : `${p.last}, ${p.first}`,
                disabled: p.status === "INACTIVE",
                type: "Client",
                email: p.email,
              })
            )[0];
          }

          this.clickedEventDetails_provider =
            this.selectedEvent.provider || this.selectedEvent.user_1;
          this.clickedEventDetails_provider_user_id =
            this.clickedEventDetails_provider.user_id ||
            this.clickedEventDetails_provider.id;
          this.select.provider = this.user1Options.filter(
            (user) => user.id == this.clickedEventDetails_provider_user_id
          )[0];

          if (!this.select.provider) {
            this.select.provider = [this.clickedEventDetails_provider].map(
              (p) => ({
                id: p.id,
                name: `${p.last}, ${p.first}`,
                disabled: p.status === "INACTIVE",
                type: p.isProvider ? "Provider" : "Admin",
                email: p.email,
              })
            )[0];
          }

          this.allowOverlap = false;

          const currentService = (this.clickedEventDetails_service || {}).id;
          this.showServicesBasedOnSelectedProvider(
            this.clickedEventDetails_provider.id
          );

          this.clickedEventDetails_service.id = currentService;
          this.select.primary = this.serviceOptions.filter(
            (service) => service.id == currentService
          )[0];

          this.clickedEventDetails_mainService = this.selectedEvent.services[0];
          const selectedPatient = this.patients.filter(
            (patient) =>
              patient.user_id == this.clickedEventDetails_patient_user_id
          )[0];
          if (
            this.clickedEventDetails_mainService &&
            this.clickedEventDetails_mainService.profession_id &&
            selectedPatient &&
            (selectedPatient.services || []).length &&
            !selectedPatient.services.includes(
              this.clickedEventDetails_mainService.id
            )
          ) {
            this.parentServices.push({
              ...this.parentServicesTemp.filter(
                (serv) => serv.id == this.clickedEventDetails_mainService.id
              )[0],
              status: "INACTIVE",
            });
          }
          var clickedEventDetails_selectedServices = null;
          if (this.selectedEvent.services.length > 1) {
            clickedEventDetails_selectedServices =
              this.selectedEvent.services.filter(
                (item) =>
                  item.parent_service_id != null || item.is_default == "true"
              );
          }
          var parentService = this.clickedEventDetails_mainService;
          // Filter it with services where the parent Id is the service Id of the event.
          var eventSubServices = [];
          let vm = this;
          this.services.forEach(function (item) {
            if (
              clickedEventDetails_selectedServices != null &&
              clickedEventDetails_selectedServices != undefined
            ) {
              var selectedItem2 = clickedEventDetails_selectedServices.filter(
                (selectedItem) =>
                  selectedItem.id == item.id &&
                  selectedItem.is_default == "true"
              );

              if (selectedItem2 != null && selectedItem2.length > 0) {
                item = selectedItem2[0];
                item.selected = true;
                if (item.unit == "FT" || item.unit == "FLAT") {
                  item.qty = 1;
                }
                eventSubServices.push(item);
                return item;
              }
            }

            if (
              parentService != null &&
              item.parent_service_id == parentService.id
            ) {
              // If this is in the sent array then it was selected before, return the saved value and set it as selected
              if (
                clickedEventDetails_selectedServices != null &&
                clickedEventDetails_selectedServices != undefined
              ) {
                var selectedItem = clickedEventDetails_selectedServices.filter(
                  (selectedItem) => selectedItem.id == item.id
                );
              }
              if (selectedItem != null && selectedItem.length > 0) {
                item = selectedItem[0];
                item.selected = true;
                if (item.pivot) {
                  item.description = item.pivot.description;
                  if (vm.clickedEventDetails_status !== "Verified") {
                    item.pivot.rate = item.rate;
                    item.pivot.unit = item.unit;
                    item.pivot.taxable = item.taxable;
                  }
                }
                if (item.unit == "FT" || item.unit == "FLAT") {
                  item.qty = 1;
                }
                eventSubServices.push(item);
                return item;
              }
              item.selected = false;
              if (item.pivot) {
                item.description = item.pivot.description;
              }
              eventSubServices.push(item);
              return item;
            }
          });
          this.clickedEventDetails_services = JSON.parse(
            JSON.stringify(eventSubServices)
          );
          this.updateDisplayedPatients();
          this.gettingApt = false;
          setTimeout(() => {
            this.changesMade = false;
          }, 0);
        })
        .catch((err) => {
          this.gettingApt = false;
          this.closeUpdateTray();
          return Swal.fire({
            title: "Error",
            text: (err.data || {}).message || "Something went wrong...",
            icon: "error",
          });
        });
    },
    showServicesBasedOnSelectedProvider(selectedUserObj) {
      let selectedProviderId = selectedUserObj;
      let isAdmin = false;
      if (selectedUserObj != null && selectedUserObj.toString().includes("_")) {
        selectedProviderId = selectedUserObj.split("_")[0];
        if (selectedUserObj.split("_")[1] == "true") {
          isAdmin = true;
        }
      }

      this.setChangesMade();
      if (this.isBillable) {
        this.clickedEventDetails_service.id = 0;
        this.select.primary = null;
      } else {
        this.clickedEventDetails_service.id = (
          this.mainNonBillableService || {}
        ).id;

        this.select.primary = this.serviceOptions.filter(
          (service) => service.id == this.clickedEventDetails_service.id
        )[0];
      }
      let vm = this;
      if (!vm.isBillable && vm.nonBillableServices.length) {
        vm.parentServices = vm.nonBillableServices;
        return;
      }

      let selectedUser = this.users.filter(
        (item) => item.id == selectedProviderId
      )[0];

      if (!isAdmin && !((selectedUser || {}).professions || []).length) {
        selectedUser = this.providers.filter(
          (item) => item.user_id == selectedProviderId
        )[0];
      }

      //show error message
      if (
        !selectedUser ||
        selectedUser.isAdmin ||
        selectedUser.isManagingAdmin
      ) {
        vm.parentServices = vm.parentServicesTemp;

        if (this.clickedEventDetails_patient_user_id) {
          const selectedPatient = this.patients.filter(
            (patient) =>
              patient.user_id == this.clickedEventDetails_patient_user_id
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter((serv) =>
              selectedPatient.services.includes(serv.id)
            );
          }
        }
        return;
      } else if (
        vm.isBillable &&
        (selectedUser.professions || []).length == 0
      ) {
        vm.parentServices = [];

        return Swal.fire({
          title: "Warning",
          text:
            this.user && this.user.isProvider
              ? "There are no services attached to you yet"
              : "The selected provider/Admin has no attached services",
          icon: "warning",
        });
      }
      if (vm.isBillable) {
        // show all parent services that has the provider profession
        vm.parentServices = [];
        const addedServicesIds = [];
        selectedUser.professions.forEach(function (profession) {
          vm.parentServicesTemp.forEach(function (item) {
            if (
              item.profession_id == profession.id &&
              !addedServicesIds.includes(item.id)
            ) {
              vm.parentServices.push(item);
              addedServicesIds.push(item.id);
            } else if (
              vm.clickedEventDetails_service &&
              vm.clickedEventDetails_service.id &&
              item.id == vm.clickedEventDetails_service?.pivot?.service_id
            ) {
              if (!addedServicesIds.includes(item.id)) {
                vm.parentServices.push({ ...item, status: "INACTIVE" });
                addedServicesIds.push(item.id);
              }
            }
          });
        });
        if (this.clickedEventDetails_patient_user_id) {
          const selectedPatient = this.patients.filter(
            (patient) =>
              patient.user_id == this.clickedEventDetails_patient_user_id
          )[0];
          if (selectedPatient && selectedPatient.services.length) {
            vm.parentServices = vm.parentServices.filter(
              (serv) =>
                selectedPatient.services.includes(serv.id) ||
                serv.id == this.clickedEventDetails_service?.pivot?.service_id
            );
          }
        }
      }
    },
    canHaveClinicalNotes: function () {
      if (
        (this.clickedEventDetails_patient || {}).id &&
        this.patients.filter(
          (patient) => patient.user_id == this.clickedEventDetails_patient.id
        ).length
      ) {
        return true;
      }
      return this.isBillable;
    },
    navigateToClinicalNotes: function () {
      const patient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient.id
      )[0];
      this.$emit("closeModal");
      this.$router.push(`/clients/${patient.id}/notes`);
    },
    createNote: function () {
      const patient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient.id
      )[0];
      this.$emit("closeModal");
      this.$router.push({
        name: "patients.notes.create",
        params: {
          userId: this.user.id,
          id: patient.id,
          appointmentId: this.clickedEventDetails_id,
        },
      });
    },
    joinMeeting: function () {
      window.open(this.clickedEventDetails_meetingUrl, "_blank");
    },
    setTimeToZero: function () {
      this.clickedEventDetails_endStr = this.clickedEventDetails_startStr;
      this.setChangesMade();
    },
    deleteEvent: function () {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      let text = "You are going to delete this session";
      if (this.clickedEventDetails_status == "Verified") {
        text =
          "This booking has been verified, are you sure you want to delete?";
      }
      return swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.actionInProgress = true;
            this.$http
              .delete("appointments/" + this.clickedEventDetails_id)
              .then(
                function (res) {
                  this.actionInProgress = false;
                  if (res) {
                    this.openUpdateTray = false;
                    Swal.fire({
                      title: "Appointment Deleted",
                      text: "Appointment Deleted Successfully",
                      icon: "success",
                    });
                    this.$emit("refetchCalendar");
                  }
                }.bind(this)
              )
              .catch((err) => {
                this.actionInProgress = false;
                if (!err.accessDenied) {
                  Swal.fire({
                    title: "Error",
                    text: (err.data || {}).message || "Something went wrong...",
                    icon: "error",
                  });
                }
                this.openUpdateTray = false;
              });
          }
        });
    },
    unapproveEvent: function () {
      this.actionInProgress = true;
      this.$http
        .put("appointments/" + this.clickedEventDetails_id + "/unverify")
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              this.openUpdateTray = false;
              Swal.fire({
                title: "Appointment Un-Verified ",
                text: "Appointment Un-Verified Successfully",
                icon: "success",
              });
              this.$emit("refetchCalendar");
            }
          }.bind(this)
        )
        .catch((err) => {
          this.actionInProgress = false;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.openUpdateTray = false;
        });
    },
    approveEvent: function () {
      if (this.changesMade) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black",
            cancelButton: "btn btn-theme ml-4",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Changes made to this event",
            text: "You made some changes to this event, do you want to save them?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Save & Verify",
            cancelButtonText: "Verify Only",
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.UpdateBook(true).then((res) => {
                if (res) {
                  vm.confirmApproveEvent();
                }
              });
            } else if (result.isDismissed && result.dismiss == "cancel") {
              vm.confirmApproveEvent();
            }
          });
      } else {
        this.confirmApproveEvent();
      }
    },
    confirmApproveEvent: function () {
      this.loading = true;
      this.actionInProgress = true;
      const patient = this.patients.filter(
        (patient) => patient.user_id == this.clickedEventDetails_patient.id
      )[0];
      this.getClinicalNotesAptIds((patient || {}).id).then((res) => {
        this.loading = false;
        this.actionInProgress = false;
        if (!res) {
          return;
        }
        const aptClinicalNote = res.filter(
          (note) => note.appointment_id == this.clickedEventDetails_id
        );
        if (
          (aptClinicalNote.length || !this.isBillable) &&
          !this.user.isProvider
        ) {
          this.sendApproveEventRequest();
        } else {
          const vm = this;
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-black ml-4",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });

          let text = "You haven't created a clinical note for this event yet!";
          if (this.user.isProvider) {
            text =
              "Once verified, you will not be able to undo this action without the assistance of the file administrator";
            if (this.canHaveClinicalNotes()) {
              text =
                "You haven't created a clinical note for this event yet!<br><br>Once verified, you will not be able to undo this action without the assistance of the file administrator";
            }
          }
          return swalWithBootstrapButtons
            .fire({
              title: "Are you sure you want to verify?",
              html: `<p class="text-left">${text}</p>`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, verify!",
              cancelButtonText: "No, cancel!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                vm.sendApproveEventRequest();
              }
            });
        }
      });
    },
    sendApproveEventRequest: function () {
      this.actionInProgress = true;
      this.$http
        .put(`appointments/${this.clickedEventDetails_id}/verify`)
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              this.openUpdateTray = false;
              Swal.fire({
                title: "Appointment Verified",
                text: "Appointment Verified Successfully",
                icon: "success",
              });
              this.$emit("refetchCalendar");
            }
          }.bind(this)
        )
        .catch((err) => {
          this.false = true;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.openUpdateTray = false;
        });
    },
    UpdateBook: function (verify = false) {
      if (this.clickedEventDetails_status === "Verified") {
        Swal.fire({
          title: "Warning",
          text: `Verified sessions can't be updated`,
          icon: "warning",
        });
        return;
      }

      if (!this.select.provider) {
        Swal.fire({
          title: "Error",
          text: `Provider/Admin field is required!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (!this.select.patient) {
        Swal.fire({
          title: "Error",
          text: `Client field is required!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (!this.select.service) {
        Swal.fire({
          title: "Error",
          text: `Service field is required!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }

      const canadaOffset = dayjs
        .tz(new Date(this.clickedEventDetails_startStr), "America/Toronto")
        .utcOffset();
      const sessionTimeDiff = dayjs(this.clickedEventDetails_endStr).diff(
        this.clickedEventDetails_startStr,
        "s"
      );
      if (sessionTimeDiff < 0) {
        this.selectTab("#nav-home-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is earlier than start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      } else if (
        sessionTimeDiff == 0 &&
        (this.clickedEventDetails_mainService.unit == "FT" ||
          this.clickedEventDetails_mainService.unit == "FLAT")
      ) {
        this.selectTab("#nav-home-tab");
        Swal.fire({
          title: "Error",
          text: `Session end time is equal to start time!`,
          icon: "error",
        });
        return Promise.resolve(false);
      }
      let data = {
        id: this.clickedEventDetails_id,
        start_time: dayjs(this.clickedEventDetails_startStr)
          .add(canadaOffset, "minute")
          .add(dayjs(this.clickedEventDetails_startStr).utcOffset(), "minute")
          .utc()
          .format(),
        end_time: dayjs(this.clickedEventDetails_endStr)
          .add(dayjs(this.clickedEventDetails_endStr).utcOffset(), "minute")
          .utc()
          .add(canadaOffset, "minute")
          .format(),
        user_2:
          this.clickedEventDetails_patient != null
            ? this.clickedEventDetails_patient_user_id
            : "",
        user_1: this.user.isProvider
          ? this.user.id
          : parseInt(this.clickedEventDetails_provider_user_id),
        memo: this.clickedEventDetails_memo,
        reason: this.clickedEventDetails_reason,
        expenses: this.isBillable ? this.clickedEventDetails_expenses : 0,
        billing_details: this.isBillable
          ? this.clickedEventDetails_billing_details
          : "",
        type: this.clickedEventDetails_type,
        primary_service: this.clickedEventDetails_mainService,
        provider_rate: this.clickedEventDetails_providerRate,
        billable: this.isBillable,
        allow_overlap: this.allowOverlap,
        hidden: this.clickedEventDetails_hidden,
      };
      if (
        this.clickedEventDetails_status != null &&
        this.clickedEventDetails_status != undefined
      ) {
        data.services = this.clickedEventDetails_services.filter(
          (item) => item.selected == true
        );
      }
      let hasEmptyQuantity = false;
      let hasNegativeQuantity = false;
      data.services.forEach((service) => {
        if (service.pivot != null && service.pivot != undefined) {
          service.qty = service.pivot.quantity;
          service.rate = service.pivot.rate;
          service.unit = service.pivot.unit;
          service.taxable = service.pivot.taxable == "1" ? true : false;
        }
        service.description = new String(service.description || "").substring(
          0,
          500
        );
        if (!service.qty) {
          hasEmptyQuantity = true;
        } else if (service.qty < 0) {
          hasNegativeQuantity = true;
        }
      });

      if (hasEmptyQuantity || hasNegativeQuantity) {
        this.selectTab("#nav-profile-tab");
        Swal.fire({
          title: "Error",
          text: hasEmptyQuantity
            ? "Quantity in selected sub services is required!"
            : "Quantity in selected sub services can't be negative!",
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (data.expenses && data.expenses < 0) {
        this.selectTab("#nav-expenses-tab");
        Swal.fire({
          title: "Error",
          text: "Expenses can't be negative!",
          icon: "error",
        });
        return Promise.resolve(false);
      }
      if (sessionTimeDiff == 0) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You have set the main service to 0 hours, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              return vm.handleUpdateBookWithRateCheck(data, verify);
            }
          });
      } else {
        if (
          !data.start_time ||
          !data.end_time ||
          !data.user_1 ||
          !data.user_2 ||
          !(data.primary_service || {}).id
        ) {
          Swal.fire({
            title: "Error",
            text: "Please fill the missing fields",
            icon: "error",
          });
          return Promise.resolve(false);
        }
        return this.handleUpdateBookWithRateCheck(data, verify);
      }
    },
    handleUpdateBookWithRateCheck: function (data, verify = false) {
      if (
        data.billable &&
        (!data.provider_rate || parseFloat(data.provider_rate) == 0)
      ) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Provider Rate is zero or empty, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              data.provider_rate = 0;
              return vm.handleBookUpdate(data, verify);
            }
          });
      } else {
        return this.handleBookUpdate(data, verify);
      }
    },
    handleBookUpdate: function (dataToUpdate, verify = false) {
      this.actionInProgress = true;
      return this.$http
        .put("appointments/" + dataToUpdate.id, dataToUpdate)
        .then(
          function (res) {
            this.actionInProgress = false;
            if (res) {
              if (!verify) {
                this.openUpdateTray = false;
                Swal.fire({
                  title: "Appointment Updated",
                  text: "Appointment Updated Successfully",
                  icon: "success",
                });
              }
              this.$emit("refetchCalendar");
            }
            return Promise.resolve(true);
          }.bind(this)
        )
        .catch((err) => {
          this.actionInProgress = false;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                ((err.response || {}).data || {}).message ||
                (err.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
          return Promise.resolve(false);
        });
    },
    addToExternalCalendar: function () {
      let data = {
        name: `${this.clickedEventDetails_service.name} - ${this.clickedEventDetails_title}`,
        description: `Service: ${
          this.clickedEventDetails_service.name
        }<br>Organizer: ${this.clickedEventDetails_organizer}<br>Invited: ${
          this.clickedEventDetails_title
        }<br>Reason: ${this.clickedEventDetails_reason || "-"}<br>Memo: ${
          this.clickedEventDetails_memo || "-"
        }<br>Type: ${this.clickedEventDetails_type}${
          this.clickedEventDetails_meetingUrl
            ? "<br>Join URL: " + this.clickedEventDetails_meetingUrl
            : ""
        }`,
        startDate: this.getPersonalCalendarDate(
          this.clickedEventDetails_startStr,
          "date"
        ),
        endDate: this.getPersonalCalendarDate(
          this.clickedEventDetails_endStr,
          "date"
        ),
        startTime: this.getPersonalCalendarDate(
          this.clickedEventDetails_startStr,
          "time"
        ),
        endTime: this.getPersonalCalendarDate(
          this.clickedEventDetails_endStr,
          "time"
        ),
        location: "",
        timeZone: dayjs.tz.guess(),
        options: [
          "Apple",
          "Google",
          "Microsoft365",
          "Outlook.com|Outlook",
          "MicrosoftTeams",
          // "Yahoo",
          "iCal",
        ],
        trigger: "click",
        listStyle: "list",
        inline: true,
        iCalFileName: `Reminder, ${this.clickedEventDetails_service.name} - ${this.clickedEventDetails_title}`,
      };
      atcb_action(data, this.$refs.externalCalendarButton);
    },
  },
};
</script>

<style lang="scss">
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #dce7f7;
}
.checkbox-input {
  box-shadow: 0 0 0 1px #060c1285;
  transform: scale(0.45);
  width: 40px;
  margin-top: -5px;
}

.atcb-list-wrapper.atcb-dropoverlay {
  top: 240px !important;
  position: fixed;
}

.loader {
  &.event-loader {
    width: 500px;
    right: 0;
    top: 0;
  }
}
</style>

<template>
  <modal
    class="transaction-modal"
    name="transaction-modal"
    id="transaction-modal"
    transition="pop-out"
    :height="454"
    :width="800"
  >
    <div class="position-relative">
      <div class="vm--top-right-slot" style="z-index: 1">
        <div class="d-flex justify-content-end noPrint">
          <div class="mr-2">
            <button
              class="btn p-0 pr-3 pt-3 border-0"
              @click="handlePrintButton"
            >
              <i class="fas fa-print fa-lg"></i>
            </button>
          </div>
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            @click="closeTransactionModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
      </div>
      <div class="">
        <div class="mx-4">
          <div>
            <h2 class="title">Transaction Receipt</h2>
          </div>
          <div>
            <b class="mr-4 title">Payer:</b>
            <span>{{ patient?.name }}</span>
          </div>
          <div>
            <b class="mr-4 title">Phone Number:</b>
            <span>{{ patient?.phone_number }}</span>
          </div>
        </div>
        <div class="card-body p-0 m-4">
          <div class="header px-4 py-3 mb-0 text-capitalize">
            {{ printTransaction.status }}
          </div>
          <div class="border-0 overflow-auto p-4" v-if="printTransaction">
            <div>
              <b class="mr-4 mt-0 title">Date and Time</b>
              <span>{{ prettyDate(printTransaction.created_at) }}</span>
            </div>
            <div>
              <b class="mr-4 title">Status</b>
              <span
                class="font-weight-bold"
                :class="
                  printTransaction.status === 'succeeded'
                    ? 'text-success'
                    : 'text-danger'
                "
                >{{ printTransaction.status }}</span
              >
            </div>
            <div>
              <b class="mr-4 title">Amount</b>
              <span>{{ printTransaction.amount }}</span>
            </div>
            <div>
              <b class="mr-4 title">Card Number</b>
              <span class="text-capitalize"
                >{{ printTransaction.brand }} ending in
                {{ printTransaction.last4 }}</span
              >
            </div>
            <div>
              <b class="mr-4 title">Reference Number</b>
              <span>{{ printTransaction.id }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
import helpers from "@/utils/helpers";

export default {
  name: "TransactionDetailsModal",
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      printTransaction: (state) => state.transactions.printTransaction,
      patient: (state) => state.patients.patient,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {},
  methods: {
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
    handlePrintButton: function () {
      window.print();
    },
    closeTransactionModal: function () {
      this.$modal.hide("transaction-modal");
    },
  },
};
</script>
<style>
.transaction-modal {
  @media print {
    .v--modal {
      width: 100vw !important;
      height: 100vh !important;
      left: 0 !important;
      top: 0 !important;
    }
  }

  .title {
    min-width: 160px;
    display: inline-flex;
    margin-top: 10px;
  }

  .header {
    background-color: #222222;
    color: #ffffff;
    border-radius: 10px 10px 0 0;
  }

  .card-body {
    border: 1px solid #222222;
    border-radius: 10px;
  }
}
</style>

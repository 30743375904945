<template>
  <div v-if="!stripeConfig.isloading && stripeConfig?.data?.is_enabled">
    <button
      v-if="!$route.meta.allUsers"
      class="btn btn-outline-blue mb-3"
      @click="addCreditCard"
    >
      <icon type="plus" /> Add Credit Card
    </button>
    <alert class="my-4" v-if="creditCards.isLoading" />
    <alert
      class="my-4"
      v-if="!creditCards.isLoading && creditCards?.data?.length == 0"
      :hideLoader="true"
      >No Credit Cards</alert
    >
    <div
      class="card border-0 overflow-auto mobile-tablet-no-card p-2 p-md-0"
      v-if="!creditCards.isLoading && creditCards?.data?.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Card Type</th>
            <th>Card Ending In</th>
            <th>Owner name</th>
            <th>Expiry</th>
            <th>Last Used</th>
            <th>Default</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="creditCard in creditCards?.data" :key="creditCard.id">
            <td>
              <b class="mobile-tablet-only mr-2">Card Type</b>
              <span class="text-capitalize">{{ creditCard.brand }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Card Ending In</b>
              <span>{{ creditCard.last4 }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Owner name</b>
              <span class="text-capitalize">{{ creditCard.owner?.name }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Expiry</b>
              <span
                >{{ creditCard.expiration_month }}/{{
                  creditCard.expiration_year
                }}</span
              >
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Last Used</b>
              <span>{{ prettyDate(creditCard.last_used) }}</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Default</b>
              <span v-if="creditCard.is_default" class="text-success h3">
                <icon type="check" />
              </span>
              <span v-else class="text-danger h3">
                <icon type="times" />
              </span>
            </td>
            <td class="text-right td-actions">
              <button
                class="btn btn-danger btn-sm"
                @click="deleteCreditCard(creditCard)"
                :disabled="creditCard.isDeleting"
              >
                <icon type="trash" v-if="!creditCard.isDeleting" />
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="creditCard.isDeleting"
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    class="d-flex align-items-center justify-content-center redirect-to-stripe"
    v-else
  >
    <span class="mr-2">Please enable Stripe from the following page</span>
    <router-link
      to="/stripe-config"
      class="nav-link p-0"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      >Stripe Configuration</router-link
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import helpers from "@/utils/helpers";
export default {
  name: "CreditCards",
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      creditCards: (state) => state.creditCards,
      patient: (state) => state.patients.patient,
      user: (state) => state.auth.user,
      stripeConfig: (state) => state.stripeConfig,
    }),
  },
  mounted() {
    this.getCreditCards(
      this.$route.meta.allUsers ? undefined : this.patient?.user_id
    );
  },
  methods: {
    ...mapActions({
      getCreditCards: "creditCards/getCreditCards",
      removeCreditCard: "creditCards/removeCreditCard",
    }),
    addCreditCard: function () {
      this.$router.push({
        name: "creditCards.create",
        params: { id: this.patient?.id },
      });
    },
    deleteCreditCard: function (creditCard) {
      this.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this Credit Card?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          creditCard.isDeleting = true;
          this.removeCreditCard({
            id: creditCard.id,
          });
          creditCard.isDeleting = false;
        }
      });
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
  },
};
</script>

<template>
  <modal
    name="acceptBookingRequestModal"
    transition="pop-out"
    class="accept-booking-request-modal"
    :width="600"
    :focus-trap="true"
    :clickToClose="false"
    height="auto"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div class="ml-3 pl-2">Please fill in the form below to proceed</div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <form @submit.prevent="acceptBookingRequestHelper">
        <div class="col-12 modal-body">
          <div
            class="form-group"
            v-if="
              loggedInUser &&
              (loggedInUser.isAdmin || loggedInUser.isManagingAdmin)
            "
          >
            <label>Provider/Admin</label>
            <multiselect
              v-model="selectedProvider"
              class="app-select no-labels"
              :options="dropdownProviders"
              :preserve-search="true"
              :allow-empty="false"
              :show-labels="false"
              placeholder="Search for Provider/Admin"
              label="name"
              :loading="professionProvidersLoading"
            >
              <template slot="option" slot-scope="props">
                <div class="d-flex flex-column">
                  <div class="font-weight-bold select-label">
                    {{ props.option.name }}
                  </div>
                  <div class="small email-container" style="opacity: 0.9">
                    ({{ props.option.email }})
                  </div>
                </div>
              </template>
              <template #noResult>
                <div class="no-result">
                  Oops! No providers found. Consider changing the search query.
                </div>
              </template>
            </multiselect>
          </div>
          <input-control
            control="number"
            v-model="providerRate"
            type="vertical"
            step="0.1"
            min="0"
          >
            Provider Rate
          </input-control>
          <div class="form-group">
            <label>
              Memo
              <i
                class="fa fa-info-circle ml-2 pointer-cursor"
                v-tooltip.top="'Seen in billing & payroll reports'"
              ></i>
            </label>
            <textarea
              class="form-control"
              v-model.trim="memo"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <label>Reason - seen by client</label>
            <textarea
              class="form-control"
              v-model.trim="reason"
              rows="3"
            ></textarea>
          </div>
          <input-control
            control="number"
            v-model="expenses"
            type="vertical"
            step="0.01"
          >
            Expenses $
          </input-control>
          <div class="form-group">
            <label> Billing Details</label>
            <textarea
              class="form-control"
              v-model.trim="billingDetails"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div
          class="
            d-flex
            justify-content-center
            border-top
            align-items-center
            py-3
          "
        >
          <button class="btn btn-danger mr-3" @click.prevent="cancel">
            Cancel
          </button>
          <save
            classes="btn btn-black"
            :saving="acceptBookingRequestLoading == bookingRequest.id"
            type="submit"
          >
            Accept Request
          </save>
        </div>
      </form>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect,
  },
  props: {
    bookingRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedProvider: null,
      providerRate: 0,
      memo: "",
      reason: "",
      expenses: null,
      billingDetails: "",
    };
  },
  async mounted() {
    if (
      this.loggedInUser &&
      (this.loggedInUser.isAdmin || this.loggedInUser.isManagingAdmin)
    ) {
      await this.getProfessionProviders({
        professionId: this.bookingRequest.treatment?.service?.profession_id,
      });
    } else if (this.loggedInUser && this.loggedInUser.isProvider) {
      await this.getProfessionProviders({
        professionId: this.bookingRequest.treatment?.service?.profession_id,
        providerId: this.loggedInUser.id,
      });
      this.selectedProvider = {
        id: this.loggedInUser.id,
        name: this.loggedInUser.name,
        email: this.loggedInUser.email,
      };
    }
    this.$forceUpdate();
  },
  updated() {
    this.calculateProviderRate();
  },
  watch: {
    selectedProvider() {
      this.calculateProviderRate();
    },
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      professionProvidersLoading: (state) =>
        state.professions.professionProviders.loading,
      professionProviders: (state) =>
        state.professions.professionProviders.data,
      acceptBookingRequestLoading: (state) =>
        state.booking.acceptBookingRequestLoading,
    }),
    dropdownProviders() {
      return this.professionProviders
        .filter((provider) => provider.status !== "INACTIVE")
        .map((provider) => ({
          id: provider.id,
          name: provider.name,
          email: provider.email,
        }));
    },
  },
  methods: {
    ...mapActions({
      getProfessionProviders: "professions/getProfessionProviders",
      acceptBookingRequest: "booking/acceptBookingRequest",
    }),
    acceptBookingRequestHelper: async function () {
      if (!this.selectedProvider) {
        Swal.fire({
          icon: "error",
          text: "Please select a provider/admin to proceed",
        });
        return;
      }

      const payload = {
        bookingRequestId: this.bookingRequest.id,
        provider_id: this.selectedProvider.id,
        provider_rate: this.providerRate,
        memo: this.memo,
        reason: this.reason,
        expenses: this.expenses,
        billing_details: this.billingDetails,
      };
      if (!this.providerRate || parseFloat(this.providerRate) == 0) {
        const vm = this;
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Provider Rate is zero or empty, are you sure you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const success = await vm.acceptBookingRequest(payload);
              if (success) {
                vm.resetFormData();
                vm.closeModal();
              }
            }
          });
      } else {
        const success = await this.acceptBookingRequest(payload);
        if (success) {
          this.resetFormData();
          this.closeModal();
        }
      }
    },
    resetFormData: function () {
      if (
        this.loggedInUser &&
        (this.loggedInUser.isAdmin || this.loggedInUser.isManagingAdmin)
      ) {
        this.selectedProvider = null;
      }
      this.providerRate = 0;
      this.memo = "";
      this.reason = "";
      this.expenses = null;
      this.billingDetails = "";
    },
    cancel: function () {
      this.resetFormData();
      this.closeModal();
    },
    closeModal: function () {
      this.$modal.hide("acceptBookingRequestModal");
    },
    calculateProviderRate: function () {
      if (this.selectedProvider) {
        const providerId = this.selectedProvider?.id;
        const service = this.bookingRequest.treatment?.service;
        if (providerId && service) {
          let selectedProvider = this.professionProviders.find(
            (user) => user.id == providerId
          );
          const providerProfession = (selectedProvider.professions || []).find(
            (profession) => profession.id == service.profession_id
          );
          if (providerProfession) {
            this.providerRate = providerProfession.rate;
          } else {
            this.providerRate = 0;
          }
        } else {
          this.providerRate = 0;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.accept-booking-request-modal {
  .modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>

import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrap from "@fullcalendar/bootstrap";

const calendarDidMountEvent = function (info) {
  if (!info?.event?.extendedProps) {
    return;
  }
  if (info.event.extendedProps.status == "Ready for Review") {
    info.backgroundColor = "#0f0";
  }
  if (info.event.extendedProps.status == "PENDING") {
    info.backgroundColor = "#ff0";
  }
  if (info.event.extendedProps.status == "Verified") {
    info.backgroundColor = "#CCC";
  }
};

export const CALENDAR_CONFIG = function (componentRef) {
  function canEnable(userId) {
    if ((componentRef.user || {}).isProvider) {
      if (componentRef.user.id !== userId) {
        return false;
      }
    }
    return true;
  }
  return {
    dayMaxEvents: 1,
    eventDidMount: calendarDidMountEvent,
    unselectAuto: false,
    businessHours: {
      daysOfWeek: [1, 2, 3, 4, 5],
      start_time: "08:00",
      end_time: "18:00",
    },
    nowIndicator: true,
    selectable: true,
    selectMirror: true,
    headerToolbar: {
      start: "title",
      center: "dayGridMonth,timeGridWeek,timeGridDay",
      end: "prevYear prev today next nextYear",
    },
    slotDuration: "00:15:00",
    scrollTime: "07:00",
    slotLabelInterval: "01:00",
    initialView: "dayGridMonth",
    themeSystem: "bootstrap",
    lazyFetching: false,
    views: {
      dayGridMonth: {
        // name of view
        displayEventEnd: true,
        showNonCurrentDates: false,
        eventContent: function (eventInfo) {
          let subServicesHours =
            eventInfo.event.extendedProps.sub_services_quantity;
          const cloneElement = `<i class="far fa-lg fa-clone calendar-clone-icon ml-auto" onclick='event.stopPropagation(); openCloneSessionModal(${eventInfo.event.id});'></i>`;
          const secretElement = `<i class="fas fa-lg fa-user-secret secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
          const unsecretElement = `<i class="fas fa-lg  fa-solid fa-solid fa-unlock secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
          const subMinutes = (
            (subServicesHours - parseInt(subServicesHours)) *
            60
          ).toFixed(0);

          const provider_name = eventInfo.event.extendedProps.provider_name;
          const user_1_id = eventInfo.event.extendedProps.provider_id;
          const htmlContent = `<div class='month-event-container ${
            eventInfo.backgroundColor == "darkgreen"
              ? " verified-card"
              : " pending-card"
          } ${eventInfo.event.extendedProps.deleted ? "deleted-session" : ""} ${
            eventInfo.event.extendedProps.billable ? "" : " non-billable-card"
          }' style='background-color: white; ${
            (eventInfo.event.extendedProps || {}).deleted
              ? `background-image: url(${require("@/assets/img/removed.jpg")})`
              : ""
          }'>
        ${
          componentRef.user &&
          (componentRef.user.isAdmin || componentRef.user.isManagingAdmin)
            ? eventInfo.event.extendedProps.hidden
              ? secretElement
              : unsecretElement
            : ""
        }
        <i class="fas fa-lg fa-check-circle verified-mark" style="display: ${
          eventInfo.backgroundColor == "darkgreen" ? "" : "none"
        };"></i>
        <div class='polit-container'>
        <div class='calendar-polit' style='background-color: rgba(${
          eventInfo.event.extendedProps.main_service_color || "204, 204, 204, 1"
        });'></div>
          </div>
      <div class="event-service ellipsis">${
        eventInfo.event.extendedProps.deleted
          ? `<h2 class='text-danger font-weight-bold m-0' style="letter-spacing: 1.2px;">REMOVED</h2>`
          : eventInfo.event.extendedProps.main_service_name || ""
      }</div>
       ${
         !eventInfo.event.extendedProps.deleted &&
         eventInfo.event.extendedProps.treatment
           ? `<div 
                class="event-service ellipsis"
                title="${eventInfo.event.extendedProps.treatment.name || ""}"
            >${eventInfo.event.extendedProps.treatment.name || ""}</div>`
           : ``
       }
      <div class="d-flex event-time-container">
      <div class="fc-daygrid-event-dot" style="display:inline-block; border-color: ${
        eventInfo.backgroundColor
      };"></div>
      <span class="event-start-end-time">${eventInfo.timeText || ""}</span>
      <span class="event-time-diff">${eventInfo.event.extendedProps.hours}h${
            eventInfo.event.extendedProps.minutes > 0
              ? " " + eventInfo.event.extendedProps.minutes + "m"
              : ""
          }</span>
        <span class="event-time-diff sub-time-diff pl-3 ${
          subServicesHours > 0 ? "" : "empty"
        }">+ ${parseInt(subServicesHours)}h${
            subMinutes > 0 ? " " + subMinutes + "m" : ""
          }</span>
      ${
        !eventInfo.event.extendedProps.isloggedinPatient &&
        !eventInfo.event.extendedProps.deleted &&
        canEnable(user_1_id)
          ? cloneElement
          : ""
      }
      </div>
      <div class="event-user-info provider-info ellipsis">
       <b>P:</b> ${provider_name}
        </div>
      <div class="event-user-info patient-info ellipsis">
        <b>C:</b> ${(eventInfo.event || {}).title || ""}
      </div>
      </div>`;
          return { html: htmlContent };
        }.bind(componentRef),
        // other view-specific options here
      },
      timeGridWeek: {
        eventContent: function (eventInfo) {
          const provider_name = eventInfo.event.extendedProps.provider_name;
          const client_name = eventInfo.event._def.title;
          window
            .$(".fc-event-main div")
            .css({ "font-size": "15px", "text-shadow": "0 0 4px #ffffff" });
          eventInfo.backgroundColor = `rgba(${
            eventInfo.event.extendedProps.main_service_color ||
            "204, 204, 204, 1"
          })`;
          eventInfo.borderColor = `rgba(${
            eventInfo.event.extendedProps.main_service_color ||
            "204, 204, 204, 1"
          })`;
          eventInfo.textColor = "black";
          const secretElement = `<i class="fas fa-lg  fa-user-secret right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
          const unsecretElement = `<i class="fas fa-lg  fa-solid fa-solid fa-unlock right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
          const htmlContainer = `<div>
             ${
               window.user &&
               (window.user.isAdmin || window.user.isManagingAdmin)
                 ? eventInfo.event.extendedProps.hidden
                   ? secretElement
                   : unsecretElement
                 : ""
             }
          <p>${eventInfo.timeText}</p>
          <p
          class="provider-name"
          ><b>P:</b> ${provider_name ? provider_name : "N/A"}</p>
          <p
          class="client-name"
          ><b>C:</b> ${client_name ? client_name : "N/A"}</p>
        </div>`;

          return { html: htmlContainer };
        },
      },
      timeGridDay: {
        eventContent: function (eventInfo) {
          const provider_name = eventInfo.event.extendedProps.provider_name;
          const client_name = eventInfo.event._def.title;

          window
            .$(".fc-event-main div")
            .css({ "font-size": "15px", "text-shadow": "0 0 4px #ffffff" });
          eventInfo.backgroundColor = `rgba(${
            eventInfo.event.extendedProps.main_service_color ||
            "204, 204, 204, 1"
          })`;
          eventInfo.borderColor = `rgba(${
            eventInfo.event.extendedProps.main_service_color ||
            "204, 204, 204, 1"
          })`;
          eventInfo.textColor = "black";
          const secretElement = `<i class="fas fa-lg  fa-user-secret right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
          const unsecretElement = `<i class="fas fa-lg  fa-solid fa-solid fa-unlock right-secret-session" onclick='event.stopPropagation(); updateHiddenValue(${eventInfo.event.id});'></i>`;
          const htmlContainer = `<div>
            ${
              window.user &&
              (window.user.isAdmin || window.user.isManagingAdmin)
                ? eventInfo.event.extendedProps.hidden
                  ? secretElement
                  : unsecretElement
                : ""
            }
          <p>${eventInfo.timeText}</p>
          <p
          class="provider-name"
          ><b>P:</b> ${provider_name ? provider_name : "N/A"}</p>
          <p
          class="client-name"
          ><b>C:</b> ${client_name ? client_name : "N/A"}</p>
        </div>`;

          return { html: htmlContainer };
        },
      },
    },
    plugins: [bootstrap, interactionPlugin, timeGridPlugin, dayGridPlugin],
  };
};
